import './Robo.css'
import TelaConfigRobo from "./TelaConfigRobo"


function Robo() {
    
    return (
        <div className="robo">
            <TelaConfigRobo />
        </div>
    )
}

export default Robo
