import { createState } from '@hookstate/core'
import { readBalance } from './firebaseCRUD'
import globalBalance, { getUserId } from './Global'

const getGanhei = () => localStorage.getItem('ganhei01') 
export const globalGanhei = createState(getGanhei() ? parseInt(getGanhei()) : 0)

const getPerdi = () => localStorage.getItem('perdi01') 
export const globalPerdi = createState(getPerdi() ? parseInt(getPerdi()) : 0)

const getAcertos = () => localStorage.getItem('acertos01') 
export const globalAcertos = createState(getAcertos() ? parseInt(getAcertos()) : 0)

const getCliclos = () => localStorage.getItem('ciclos01') 
export const globalCiclos = createState(getCliclos() ? parseInt(getCliclos()) : 0)

const getLucroRestante = () => localStorage.getItem('lucroRestante01') 
export const globalLucroRestante = createState(getLucroRestante() ? parseInt(getLucroRestante()) : 0)

const getOportunidades = () => localStorage.getItem('oportunidades01') 
export const globalOportunidades = createState(getOportunidades() ? parseInt(getOportunidades()) : 14)

const getRetornoPorCiclo = () => localStorage.getItem('retornoPorCiclo01') 
export const globalRetornoPorCiclo = createState(getRetornoPorCiclo() ? parseFloat(getRetornoPorCiclo()) : parseFloat(0.0090))

const getProxValor = () => localStorage.getItem('proxValor01') 
export const globalProxValor = createState(getProxValor() ? parseFloat(getProxValor()) : 
                                           globalBalance.get() ? 0.362 * globalBalance.get()
                                           : 0.362 *  parseFloat( readBalance(getUserId())) | 0)
