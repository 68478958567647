import { useState, useEffect, useRef } from 'react'
import './EditInPlace.css'
import EditIcon from '@material-ui/icons/Edit'
import { useState as useGlobalState } from '@hookstate/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import globalBalance, { globalReset, 
                        globalInitialBalance, 
                        globalEditedBalance,
                        globalUser,
                        globalMinimum,
                        globalIsEditing
                      } from './Global'
import { updateBalance, updateDataSlice } from './firebaseCRUD'
import { resetProxValor } from './resetData';

function EditInPlace() {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const gBalance = useGlobalState(globalBalance)
    const gReset = useGlobalState(globalReset)
    const gInitialBalance = useGlobalState(globalInitialBalance)
    const gEditedBalance = useGlobalState(globalEditedBalance)
    const gMinimum = useGlobalState(globalMinimum)
    const [open, setOpen] = useState(() => gBalance.get() <= gMinimum.get());

    const gIsEditing = useGlobalState(globalIsEditing)
    const [isEditing, setIsEditing] = useState(false)

    const handleEditing = () => {
        setIsEditing(true)
        gIsEditing.set(() => true)
    }
    const handleBlur = () => {
        setIsEditing(false)
        gIsEditing.set(() => false)
    }
    const inputRef = useRef(null)
    const gUser = useGlobalState(globalUser)

    const getUserId = () => {
        return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
        typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
      }
      

    const handleKeyDown = (event, type) => {
        const { key } = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey]; // All keys array

        if (
          (type === "textarea" && keys.indexOf(key) > -1) ||
          (type !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
           setIsEditing(false);
           gIsEditing.set(() => false)
        }
    }

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus()
        }
        
        localStorage.setItem('balance',gBalance.get())
        
    },[isEditing])

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })
    
    
    const handleFocus = (event) => event.target.select();
    
    useEffect(() => {
        setOpen(() => gBalance.get() < gMinimum.get())

        if (!gBalance.get()) {
            gBalance.set(() => 0)
        }
        
    },[gBalance.get()])

    useEffect(() => {
        resetProxValor()
    },[isEditing])

    if (isEditing) {    
        return <input   className="editInPlace__balance editInPlace__input"
                        value={Math.round((gBalance.get() + Number.EPSILON) * 100) / 100} 
                        type='number' 
                        ref={inputRef} 
                        onBlur={handleBlur} 
                        onFocus={gBalance.get() === 0 | gBalance.get() === 10000? handleFocus : null}
                        onChange={e => {
                            updateBalance(getUserId(), parseFloat(e.target.value))
                            gBalance.set(() => parseFloat(e.target.value))

                            console.log("e.target.value",e.target.value)
                            gInitialBalance.set(() => gBalance.get())
                            localStorage.setItem('initialBalance',gInitialBalance.get())

                            gEditedBalance.set(() => gBalance.get())
                            localStorage.setItem('editedBalance',gEditedBalance.get())
                            updateDataSlice(getUserId(), { editedBalance: gEditedBalance.get() })

                            gReset.set(() => false)
                            localStorage.setItem('reset',gReset.get())
                        }} 
                        onKeyDown={e => handleKeyDown(e, 'number')} />
    }

    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
    
    return (
        <>
        <div onClick={handleEditing} className="editInPlace">
            <h1 className="editInPlace__balance">{formatter.format(gBalance.get())}</h1>
            <EditIcon />
        </div>
        {gBalance.get() < gMinimum.get() && 
        <Snackbar open={open} 
                  autoHideDuration={6000} 
                  onClose={handleClose}
                  anchorOrigin={{ vertical:"top", horizontal:"right" }}
                  >
            <Alert onClose={handleClose} severity="error">
            {`O valor da banca é menor do que o mínimo para essa opção!
            (${formatter.format(gMinimum.get())})`} 
            </Alert>
        </Snackbar>}
        </>
    )
}

export default EditInPlace
