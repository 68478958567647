import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyA3EK3YmE8Deu3NGm4Z5sYUAl2G5kQUIQE",
  authDomain: "ogerenciamento.com",
  projectId: "growup-21f06",
  storageBucket: "growup-21f06.appspot.com",
  messagingSenderId: "240946833731",
  appId: "1:240946833731:web:a1af3f11efbdd2873ea7f6"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider();
const app = firebase.app()

export { auth, provider, app }
export default db
