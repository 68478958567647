import firebase from 'firebase'
import { balance } from './features/balanceSlice';
import { software } from './features/softwareSlice';
import globalBalance, { 
    globalPlan, globalOption, globalGanhei, globalPerdi, globalHojeTaxa, globalEditedBalance
 } from './Global'

import {
        globalAcertos as globalAcertos00, 
        globalCiclos as globalCiclos00, 
        globalOportunidades as globalOportunidades00, 
        globalProxValor as globalProxValor00,
        globalLucroRestante as globalLucroRestante00 } from './Global00'

import {
    globalAcertos as globalAcertos01, 
    globalCiclos as globalCiclos01, 
    globalOportunidades as globalOportunidades01, 
    globalProxValor as globalProxValor01,
    globalLucroRestante as globalLucroRestante01 } from './Global01'

import {
    globalAcertos as globalAcertos02, 
    globalCiclos as globalCiclos02, 
    globalOportunidades as globalOportunidades02, 
    globalProxValor as globalProxValor02,
    globalLucroRestante as globalLucroRestante02 } from './Global02'

import {
    globalAcertos as globalAcertos03, 
    globalCiclos as globalCiclos03, 
    globalOportunidades as globalOportunidades03, 
    globalProxValor as globalProxValor03,
    globalLucroRestante as globalLucroRestante03 } from './Global03'

import {
    globalAcertos as globalAcertos10, 
    globalCiclos as globalCiclos10, 
    globalOportunidades as globalOportunidades10, 
    globalProxValor as globalProxValor10,
    globalLucroRestante as globalLucroRestante10} from './Global10'


import {
    globalAcertos as globalAcertos11, 
    globalCiclos as globalCiclos11, 
    globalOportunidades as globalOportunidades11, 
    globalProxValor as globalProxValor11,
    globalLucroRestante as globalLucroRestante11 } from './Global11'

import {
    globalAcertos as globalAcertos12, 
    globalCiclos as globalCiclos12, 
    globalOportunidades as globalOportunidades12, 
    globalProxValor as globalProxValor12,
    globalLucroRestante as globalLucroRestante12 } from './Global12'

import {
    globalAcertos as globalAcertos13, 
    globalCiclos as globalCiclos13, 
    globalOportunidades as globalOportunidades13, 
    globalProxValor as globalProxValor13,
    globalLucroRestante as globalLucroRestante13 } from './Global13'

import resetData from './resetData';


const readData = (userId, dispatch) => {
    console.log("readData before...")
    const customersRef = firebase.firestore().collection("/customers");
    const userDocRef = customersRef.doc(userId);
    // read first time
    userDocRef.get().then((userDoc) => {
        
        console.log("readData inside",userDoc.data())
        if (userDoc.exists) { //&& userDoc.data()?.plan) {
            console.log("readData inside inside",userDoc.data())
            globalBalance.set(() => userDoc.data()?.balance ? userDoc.data()?.balance : 0)
            localStorage.setItem('balance',userDoc.data()?.balance ? userDoc.data()?.balance : 0)

            globalPlan.set(() => userDoc.data()?.plan)
            globalOption.set(() => userDoc.data()?.option)
            globalHojeTaxa.set(() => userDoc.data()?.hojeTaxa)
            globalEditedBalance.set(() => userDoc.data()?.editedBalance)

            globalGanhei.set(() => userDoc.data()?.ganhei)
            globalPerdi.set(() => userDoc.data()?.perdi)
            
            globalAcertos00.set(() => userDoc.data()?.acertos00)
            globalCiclos00.set(() => userDoc.data()?.ciclos00)
            globalOportunidades00.set(() => userDoc.data()?.lucroRestante00)
            globalProxValor00.set(() => userDoc.data()?.oportunidades00)
            globalLucroRestante00.set(() => userDoc.data()?.proxValor00)

           
            globalAcertos01.set(() => userDoc.data()?.acertos01)
            globalCiclos01.set(() => userDoc.data()?.ciclos01)
            globalOportunidades01.set(() => userDoc.data()?.lucroRestante01)
            globalProxValor01.set(() => userDoc.data()?.oportunidades01)
            globalLucroRestante01.set(() => userDoc.data()?.proxValor01)

            
            globalAcertos02.set(() => userDoc.data()?.acertos02)
            globalCiclos02.set(() => userDoc.data()?.ciclos02)
            globalOportunidades02.set(() => userDoc.data()?.lucroRestante02)
            globalProxValor02.set(() => userDoc.data()?.oportunidades02)
            globalLucroRestante02.set(() => userDoc.data()?.proxValor02)

            
            globalAcertos03.set(() => userDoc.data()?.acertos03)
            globalCiclos03.set(() => userDoc.data()?.ciclos03)
            globalOportunidades03.set(() => userDoc.data()?.lucroRestante03)
            globalProxValor03.set(() => userDoc.data()?.oportunidades03)
            globalLucroRestante03.set(() => userDoc.data()?.proxValor03)

           
            globalAcertos10.set(() => userDoc.data()?.acertos10)
            globalCiclos10.set(() => userDoc.data()?.ciclos10)
            globalOportunidades10.set(() => userDoc.data()?.lucroRestante10)
            globalProxValor10.set(() => userDoc.data()?.oportunidades10)
            globalLucroRestante10.set(() => userDoc.data()?.proxValor10)

           
            globalAcertos11.set(() => userDoc.data()?.acertos11)
            globalCiclos11.set(() => userDoc.data()?.ciclos11)
            globalOportunidades11.set(() => userDoc.data()?.lucroRestante11)
            globalProxValor11.set(() => userDoc.data()?.oportunidades11)
            globalLucroRestante11.set(() => userDoc.data()?.proxValor11)

            
            globalAcertos12.set(() => userDoc.data()?.acertos12)
            globalCiclos12.set(() => userDoc.data()?.ciclos12)
            globalOportunidades12.set(() => userDoc.data()?.lucroRestante12)
            globalProxValor12.set(() => userDoc.data()?.oportunidades12)
            globalLucroRestante12.set(() => userDoc.data()?.proxValor12)

            globalAcertos13.set(() => userDoc.data()?.acertos13)
            globalCiclos13.set(() => userDoc.data()?.ciclos13)
            globalOportunidades13.set(() => userDoc.data()?.lucroRestante13)
            globalProxValor13.set(() => userDoc.data()?.oportunidades13)
            globalLucroRestante13.set(() => userDoc.data()?.proxValor13)

            parseInt(localStorage.setItem('plan',userDoc.data()?.plan))
            parseInt(localStorage.setItem('option',userDoc.data()?.option))
            parseFloat(localStorage.setItem('hojeTaxa',userDoc.data()?.hojeTaxa))
            parseFloat(localStorage.setItem('editedBalance',userDoc.data()?.editedBalance))

            parseFloat(localStorage.setItem('ganhei',userDoc.data()?.ganhei))
            parseFloat(localStorage.setItem('perdi',userDoc.data()?.perdi))

            parseFloat(localStorage.setItem('acertos00',userDoc.data()?.acertos00))
            parseFloat(localStorage.setItem('ciclos00',userDoc.data()?.ciclos00))
            parseFloat(localStorage.setItem('lucroRestante00',userDoc.data()?.lucroRestante00))
            parseFloat(localStorage.setItem('oportunidades00',userDoc.data()?.oportunidades00))
            parseFloat(localStorage.setItem('proxValor00',userDoc.data()?.proxValor00))


            parseFloat(localStorage.setItem('acertos01',userDoc.data()?.acertos01))
            parseFloat(localStorage.setItem('ciclos01',userDoc.data()?.ciclos01))
            parseFloat(localStorage.setItem('lucroRestante01',userDoc.data()?.lucroRestante01))
            parseFloat(localStorage.setItem('oportunidades01',userDoc.data()?.oportunidades01))
            parseFloat(localStorage.setItem('proxValor01',userDoc.data()?.proxValor01))


            parseFloat(localStorage.setItem('acertos02',userDoc.data()?.acertos02))
            parseFloat(localStorage.setItem('ciclos02',userDoc.data()?.ciclos02))
            parseFloat(localStorage.setItem('lucroRestante02',userDoc.data()?.lucroRestante02))
            parseFloat(localStorage.setItem('oportunidades02',userDoc.data()?.oportunidades02))
            parseFloat(localStorage.setItem('proxValor02',userDoc.data()?.proxValor02))


            parseFloat(localStorage.setItem('acertos03',userDoc.data()?.acertos03))
            parseFloat(localStorage.setItem('ciclos03',userDoc.data()?.ciclos03))
            parseFloat(localStorage.setItem('lucroRestante03',userDoc.data()?.lucroRestante03))
            parseFloat(localStorage.setItem('oportunidades03',userDoc.data()?.oportunidades03))
            parseFloat(localStorage.setItem('proxValor03',userDoc.data()?.proxValor03))


            parseFloat(localStorage.setItem('acertos10',userDoc.data()?.acertos10))
            parseFloat(localStorage.setItem('ciclos10',userDoc.data()?.ciclos10))
            parseFloat(localStorage.setItem('lucroRestante10',userDoc.data()?.lucroRestante10))
            parseFloat(localStorage.setItem('oportunidades10',userDoc.data()?.oportunidades10))
            parseFloat(localStorage.setItem('proxValor10',userDoc.data()?.proxValor10))


            parseFloat(localStorage.setItem('acertos11',userDoc.data()?.acertos11))
            parseFloat(localStorage.setItem('ciclos11',userDoc.data()?.ciclos11))
            parseFloat(localStorage.setItem('lucroRestante11',userDoc.data()?.lucroRestante11))
            parseFloat(localStorage.setItem('oportunidades11',userDoc.data()?.oportunidades11))
            parseFloat(localStorage.setItem('proxValor11',userDoc.data()?.proxValor11))


            parseFloat(localStorage.setItem('acertos12',userDoc.data()?.acertos12))
            parseFloat(localStorage.setItem('ciclos12',userDoc.data()?.ciclos12))
            parseFloat(localStorage.setItem('lucroRestante12',userDoc.data()?.lucroRestante12))
            parseFloat(localStorage.setItem('oportunidades12',userDoc.data()?.oportunidades12))
            parseFloat(localStorage.setItem('proxValor12',userDoc.data()?.proxValor12))

            parseFloat(localStorage.setItem('acertos13',userDoc.data()?.acertos13))
            parseFloat(localStorage.setItem('ciclos13',userDoc.data()?.ciclos13))
            parseFloat(localStorage.setItem('lucroRestante13',userDoc.data()?.lucroRestante13))
            parseFloat(localStorage.setItem('oportunidades13',userDoc.data()?.oportunidades13))
            parseFloat(localStorage.setItem('proxValor13',userDoc.data()?.proxValor13))
            
            if (dispatch) {
                dispatch(balance({value: userDoc.data()?.balance}))
                dispatch(
                    software(  
                        userDoc.data()
                        )     
                )
            }
                
        } else if (userDoc.exists && userDoc.data().length < 12){
            console.log("GOT HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
            resetData(userId)
        } 
    }).catch((error) => {
        console.log("Error getting user:", error);
    });  

}

export default readData






