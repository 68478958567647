import firebase from 'firebase'
import { balance } from './features/balanceSlice';
import globalBalance, { globalEditedBalance, globalHojeTaxa, globalSubscription } from './Global'

let customersRef = firebase.firestore().collection("/customers");

// UPDATE
export const updateBalance = (userId, value) => {
    customersRef.doc(userId).update({
        balance: value,
        }).then(() => console.log("Balance Updated Successfully!")
        ).catch(e => console.log("An error has occurred!",e))
}

export const updateDataSlice = (userId, data) => {
    customersRef.doc(userId).update(data).then(() => console.log("Customers Updated Successfully!")
    ).catch(e => console.log("An error has occurred!",e))
}

export const readBalance = (userId, dispatch) => {
    let userDocRef = customersRef.doc(userId);
    // read first time
    userDocRef.get().then((userDoc) => {
        // console.log("BALANCE", userDoc.data())
        // globalHojeTaxa.set(() => userDoc.data()?.hojeTaxa)
        // localStorage.setItem('hojeTaxa', userDoc.data()?.hojeTaxa)
        // console.log("USER_ID",userId)
        if (userDoc.exists) {
            localStorage.setItem('balance',userDoc.data()?.balance ? userDoc.data()?.balance : 0)
            globalBalance.set(() => userDoc.data()?.balance ? userDoc.data()?.balance : 0)

            localStorage.setItem('hojeTaxa',userDoc.data()?.hojeTaxa ? userDoc.data()?.hojeTaxa : 0)
            globalHojeTaxa.set(() => userDoc.data()?.hojeTaxa ? userDoc.data()?.hojeTaxa : 0)

            localStorage.setItem('editedBalance',userDoc.data()?.editedBalance ? userDoc.data()?.editedBalance : 0)
            globalEditedBalance.set(() => userDoc.data()?.editedBalance ? userDoc.data()?.editedBalance : 0)



            
            if (dispatch) {
                dispatch(balance({value: userDoc.data()?.balance}))
            }
                
            // return userDoc.data()
        } else {
            // doc.data() will be undefined in this case
            console.log("No such user!");
        }
    }).catch((error) => {
        console.log("Error getting user:", error);
    });  

}

export const readPlan = (userId, history) => {
    let userDocRef = customersRef.doc(userId);

    userDocRef.collection('subscriptions')
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(async subscription => {
                // setSubscription({
                //     role: subscription.data().role,
                //     current_period_end: subscription.data().current_period_end.seconds,
                //     current_period_start: subscription.data().current_period_start.seconds,
                //     cancel_at_period_end: subscription.data().cancel_at_period_end
                // })
                localStorage.setItem('subscription',subscription.data()?.role) 
                globalSubscription.set(() => subscription.data()?.role)
                {subscription.data() &&
                history?.push('/dashboard', { update: true })}
            })?.catch((error) => {
                console.log("Error getting subscription:", error);
            });  
        }) 
}




export const readHojeTaxa = (userId) => {
    let userDocRef = customersRef.doc(userId);
    userDocRef.get().then((userDoc) => {
        if (userDoc.exists) {
            localStorage.setItem('hojeTaxa',userDoc.data()?.hojeTaxa)
            globalHojeTaxa.set(() => userDoc.data()?.hojeTaxa)
        } else {
            console.log("No such user!");
        }
    }).catch((error) => {
        console.log("Error getting user:", error);
    });  

}
