import './Termos.css'

function Termos() {
    return (
        <div className='termos__container'>
        <div>
    <strong>Termos de uso do O Gerenciamento</strong>
</div>
<div>
    O Gerenciamento é um software que utiliza algoritmos matemáticos para
    calcular automaticamente o valor de cada entrada que você deverá fazer,
    baseando-se nos seus erros e acertos para obter o lucro que você deseja, de
    forma simples e eficiente. Obtendo lucro sempre que acertar apenas 2
    operações, consecutivas ou não dependendo das oportunidades que você
    selecionar.
</div>
<div>
    Estes Termos de uso regulam a sua utilização da assinatura do software O
    Gerenciamento. Usados nestes Termos de uso, os termos “assinatura O
    Gerenciamento”, “nosso serviço” ou “o serviço” significam o serviço
    personalizado fornecido pelo software O Gerenciamento, para buscar e
    acessar conteúdo O Gerenciamento, incluindo todos os recursos e
    funcionalidades, recomendações e avaliações, nosso site e as interfaces do
    usuário, assim como todo o conteúdo e software associado ao serviço.
</div>
<div>
    Assinatura
</div>
<div>
    <br/>
</div>
<div>
    1.1. Sua assinatura no software o Gerenciamento continuará até que seja
    cancelada. Para utilizar o serviço do O Gerenciamento, você precisa ter
    acesso à Internet e um aparelho celular, computador, notebook ou tablet,
    bem como fornecer uma Forma de Pagamento. “Forma de Pagamento” refere-se a
    uma forma de pagamento atualizada, válida e aceitável, que poderá ser
    atualizada periodicamente, e que poderá incluir o pagamento por meio da sua
    conta com terceiros. A menos que cancele a assinatura antes da data de
    cobrança, você nos autoriza a cobrar a taxa de assinatura do próximo ciclo
    de faturamento usando a sua Forma de Pagamento (consulte “Cancelamento”
    abaixo).
</div>
<div>
    <br/>
</div>
<div>
    Cupons promocionais. O Gerenciamento poderá disponibilizar ofertas, planos
    ou assinaturas promocionais especiais (“Ofertas”). A elegibilidade para as
    Ofertas é determinada pelo O Gerenciamento a seu exclusivo critério. O
    Gerenciamento se reserva o direito de revogar uma Oferta e suspender sua
    conta se determinarmos que você não é elegível. Os requisitos de
    elegibilidade, bem como outras limitações e condições, serão revelados
    quando você se inscrever para a oferta ou em outras comunicações que
    disponibilizaremos para você.
</div>
<div>
    <br/>
</div>
<div>
    <br/>
</div>
<div>
    <strong>Faturamento e cancelamento</strong>
</div>
<div>
    <br/>
</div>
<div>
    3.1. Formas de Pagamento. Para utilizar o serviço O Gerenciamento, você
    precisa fornecer uma Forma de Pagamento. Você nos autoriza a cobrar de
    forma recorrente o pagamento associado a Forma de Pagamento selecionada por
    você no momento da assinatura. Se um pagamento não for realizado com
    sucesso, devido à perda de validade, insuficiência de saldo ou qualquer
    outro motivo, e você não cancelar a sua conta, poderemos suspender o seu
    acesso ao serviço até que consigamos fazer a cobrança de uma Forma de
    Pagamento válida.
</div>
<div>
    <br/>
</div>
<div>
    <br/>
</div>
<div>
    3.2. Cancelamento. Você pode cancelar sua assinatura O Gerenciamento a
    qualquer momento e você continuará a ter acesso ao serviço do nosso
    software até o fim do período de faturamento. Na extensão permitida pelas
    leis aplicáveis, os pagamentos não são reembolsáveis e O Gerenciamento não
    oferece reembolsos ou créditos por períodos de assinatura utilizados
    parcialmente ou por acesso não utilizado. Para cancelar, acesse a página
    “Meu Plano” e em seguida clique em “Cancelar” o seu plano atual. Se você
    cancelar sua assinatura, sua conta será automaticamente encerrada ao fim do
    período de cobrança em andamento. Para verificar quando sua conta será
    encerrada, observe o campo “Data de Renovação” no campo no “Meu Plano”.
</div>
<div>
    <br/>
</div>
<div>
    3.3. Alterações de preços e planos de assinatura. De tempos em tempos,
    poderemos alterar nossos planos de assinatura e os preços de nosso serviço.
    Os valores de assinatura poderão ser revisados periodicamente para melhor
    adequação ao contínuo aprimoramento de nosso serviço. Nós também poderemos
    ajustar o valor de sua assinatura anualmente, ou com maior frequência se
    permitido pela legislação vigente, de acordo com a inflação estabelecida
    pelo Índice Geral de Preços do Mercado (IGP-M), publicado pela Fundação
    Getúlio Vargas, ou outro índice equivalente aplicável ao serviço O
    Gerenciamento. Quaisquer alterações de preço ou alterações em seus planos
    de assinatura serão aplicadas somente 30 dias após o envio da notificação a
    você.
</div>
<div>
    <br/>
</div>
<div>
    <br/>
</div>
<div>
    4. Você concorda em usar o serviço O Gerenciamento, incluindo todos os
    recursos e funcionalidades associadas de acordo com as leis, regras e
    regulamentos aplicáveis ou outras restrições de uso do serviço ou conteúdo
    previstas nas mesmas. Você também concorda em não arquivar, reproduzir,
    distribuir, modificar, exibir, executar, publicar, licenciar ou criar
    trabalhos derivados, colocar à venda ou utilizar (exceto nas formas
    expressamente autorizadas por estes Termos de uso) o conteúdo e as
    informações contidas ou obtidas do serviço O Gerenciamento ou por meio
    deste. Você também concorda em não contornar, remover, alterar, desativar,
    degradar ou adulterar quaisquer das proteções de conteúdo do serviço O
    Gerenciamento, usar engenharia reversa ou desmembrar qualquer software ou
    outros produtos ou processos acessíveis pelo serviço O Gerenciamento,
    inserir qualquer código ou produto ou manipular o conteúdo do serviço O
    Gerenciamento de qualquer forma ou usar métodos de coleta de dados ou
    extração de dados. Além disso, você concorda em não fazer upload, publicar,
    enviar por e-mail, comunicar ou transmitir de qualquer forma qualquer
    material designado para interromper, destruir ou limitar a funcionalidade
    de qualquer software, hardware ou equipamento de telecomunicações associado
    ao serviço O Gerenciamento, incluindo vírus de software, código, arquivos
    ou programas. O Gerenciamento poderá cancelar ou restringir seu uso do
    serviço se você violar estes Termos de uso ou se envolver no uso ilegal ou
    fraudulento do serviço.
</div>
<div>
    <br/>
</div>
<div>
    4.1. A qualidade da imagem do software O Gerenciamento pode variar de
    aparelho para aparelho e pode ser afetada por diversos fatores, incluindo
    sua localização, a largura de banda disponível e a velocidade da sua
    conexão com a Internet. Você se responsabiliza por todas as tarifas de
    acesso à Internet. Por favor, consulte seu provedor de Internet para obter
    informações sobre os custos de utilização de dados.
</div>
<div>
    <br/>
</div>
<div>
    <br/>
</div>
<div>
    Senhas e acesso à conta. O assinante que criou a conta no software O
    Gerenciamento e cuja Forma de Pagamento é cobrada pelo serviço
    (“Proprietário da Conta”) é responsável por qualquer atividade que ocorra
    na conta O Gerenciamento. Para manter o controle sobre a conta e evitar que
    qualquer pessoa possa acessá-la (o que incluiria informações dos títulos
    assistidos da conta), o Proprietário da Conta deve manter o controle sobre
    os aparelhos compatíveis com O Gerenciamento utilizados para acessar o
    serviço e não revelar a ninguém a senha ou os detalhes da Forma de
    Pagamento associada à conta. Você é responsável por atualizar e manter
    exatas as informações fornecidas ao O Gerenciamento relativas à sua conta.
    O Gerenciamento poderá cancelar ou suspender a sua conta para proteger
    você, O Gerenciamento ou nossos parceiros contra falsidade ideológica ou
    outras atividades fraudulentas.
</div>
<div>
    <br/>
</div>
<div>
    Garantias e isenção de responsabilidades. O SOFTWARE O GERENCIAMENO É
    FORNECIDO “NO ESTADO EM QUE SE ENCONTRA”, SEM GARANTIAS OU CONDIÇÕES.
    ESPECIFICAMENTE, O NOSSO SERVIÇO PODE NÃO ESTAR LIVRE DE INTERRUPÇÕES OU
    ERROS. VOCÊ RENUNCIA A TODAS E QUAISQUER INDENIZAÇÕES POR DANOS INDIRETOS,
    MORAIS E MATERIAIS CONTRA O GERENCIAMENTO. O GERENCIAMENTO NÃO É
    RESPONSÁVEL POR QUALQUER EVENTUAL PERDA GERADA EM SUA CONTA OPERACIONAL.
    Estes Termos não limitarão nenhuma garantia e nenhum direito obrigatório
    não renunciável de proteção do consumidor que se aplicam a você.
</div>
<div>
    <br/>
</div>
<div>
    <strong>Diversos</strong>
</div>
<div>
    <br/>
</div>
<div>
    7.1. Legislação aplicável. Estes Termos de uso devem ser regidos por e
    interpretados de acordo com a legislação do Brasil.
</div>
<div>
    <br/>
</div>
<div>
    7.2. Atendimento ao cliente. Para obter mais informações sobre o serviço O
    Gerenciamento, suas funcionalidades e recursos, ou caso precise de ajuda
    com sua conta, por favor, acesse o Centro de ajuda O Gerenciamento no site
   <a className='site_link' style={{ marginLeft: 5, color: 'blue', textDecoration: 'underline'}} href="https://ogerenciamento.com">www.ogerenciamento.com</a>
</div>
<div>
    <br/>
</div>
<div>
    Em alguns casos, a melhor opção para o atendimento ao cliente é utilizar o
    perfil no Instagram do próprio software ou o WhatsApp de suporte fornecido
    em nosso site. O Gerenciamento ajudará você de outras formas. No caso de
    quaisquer conflitos entre estes Termos de uso e informações fornecidas pelo
    atendimento ao cliente ou outras partes dos nossos sites, estes Termos de
    uso prevalecerão.
</div>
<div>
    <br/>
</div>
<div>
    7.4. Subsistência. Se qualquer disposição ou disposições destes Termos de
    uso forem consideradas inválidas, ilegais ou não aplicáveis, a validade,
    legalidade e aplicabilidade das demais disposições devem permanecer em
    pleno vigor.
</div>
<div>
    <br/>
</div>
<div>
    7.5. Alterações dos termos de uso e cessão. O Gerenciamento poderá alterar
    estes Termos de uso periodicamente. Notificaremos você com pelo menos 30
    dias de antecedência antes que as alterações se apliquem a você. A qualquer
    momento, O Gerenciamento poderá ceder ou transferir o nosso contrato com
    você, inclusive nossos direitos e obrigações associados. Você concorda em
    cooperar com O Gerenciamento nessas cessões ou transferências.
</div>
<div>
    <br/>
</div>
</div>
    )
}

export default Termos
