import { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './SelectPlan.css'
import { useHistory } from 'react-router-dom'
import { getUserId, globalSubscription, globalUser } from './Global'
import { useState as useGlobalState } from '@hookstate/core'
import { useDispatch } from 'react-redux';
import logUserOut from './logUserOut';
import { v4 } from 'uuid'

export default function SimpleSelect({children}) {
  
  const [logout, setLogout] = useState(0)
  const history = useHistory()
  const gUser = useGlobalState(globalUser)
  const gSubscription = useGlobalState(globalSubscription)
  const dispatch = useDispatch()

  const handleChange = (event) => {
    setLogout(() => event.target.value)
  }

  const userId = localStorage.getItem("uid") ? localStorage.getItem("uid") :  getUserId()
  if (userId) {
    localStorage.setItem('uid',userId)
  }

  useEffect(() => {
    if (logout === 2) {  
      console.log("Calling logUserOut!!!!")   
      logUserOut(dispatch, gSubscription, gUser,history)
    }
    if (logout === 1) {
      history.push('/meu-plano')
    }
  },[logout])



  return (
    <div>
      
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={logout}
          onChange={handleChange}
        >
          <MenuItem key={v4()} value={0}>{children}</MenuItem>
          <MenuItem key={v4()} value={1}>Meu Plano</MenuItem>
          <MenuItem key={v4()} value={2}>Sair da Conta</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}



