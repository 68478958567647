import './Header.css'
import { auth, provider } from './firebase'
import { useState as useGlobalState } from '@hookstate/core'
import globalBalance, { globalHistory, globalLosses, globalPrevLoc, globalProxValor, globalRetornoPorCiclo, globalSubscription, globalUser } from './Global'
import { globalProxValor as globalProxValor00 } from './Global00'
import { globalProxValor as globalProxValor01 } from './Global01'
import { globalProxValor as globalProxValor02 } from './Global02'
import { globalProxValor as globalProxValor03 } from './Global03'
import { globalProxValor as globalProxValor10 } from './Global10'
import { globalProxValor as globalProxValor11 } from './Global11'
import { globalProxValor as globalProxValor12 } from './Global12'
import { Persistence } from '@hookstate/persistence';
import { useHistory } from 'react-router-dom'
import { readBalance, readPlan } from './firebaseCRUD'
import { login } from './features/userSlice';
import readData from './readData';
import db from './firebase'
import { selectBalance } from './features/balanceSlice'
import { useDispatch, useSelector } from 'react-redux'


function Header() {
    const gUser = useGlobalState(globalUser)
    const history = useHistory()
    const gPrevLoc = useGlobalState(globalPrevLoc)
    const dispatch = useDispatch()
    const balance = useSelector(selectBalance)
    const gBalance = useGlobalState(globalBalance)
    const gRetornoPorCiclo = useGlobalState(globalRetornoPorCiclo)

    const getUserId = () => {
        return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
        typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
    }

    // const signIn = () => {
    //     auth.signInWithPopup(provider).then(
    //         user => {
    //             gUser.set(() => user)
    //             gUser.attach(Persistence('user'))
    //             gPrevLoc.set(() => 'home')
    //             readPlan(getUserId(), history)
    //             history.push('/dashboard', { update: true })
                
    //         }
            
    //     ).catch(error => alert("HEADER: Erro no Login:",error.message))
        
    // }
    const signIn = () => {
        auth.signInWithPopup(provider).then(
            user => {
                dispatch(
                    login({
                        uid: user.user.uid,
                        email: user.user.email,
                        displayName: user.user.displayName,
                        photoURL: user.user.photoURL
                    }) 
                )               
                
                readBalance(user?.user?.uid, dispatch)
                localStorage.setItem("uid",user?.user?.uid)
                console.log("READING DATA...")
                readData(user?.user?.uid, dispatch)


                db.collection('customers').doc(user.user.uid)
                .collection('trades')
                .orderBy('timestamp','asc')
                .onSnapshot( tradeSnapshot => {
                    tradeSnapshot.docs.map( trade => globalHistory.set( p => [trade.data(), ...p]))
                })
            

                if (balance) {
                    gBalance.set(() => balance?.value)
                    localStorage.setItem('balance',balance?.value)
                    console.log("balance?.value",balance?.value)
                }
                console.log("gBalance.get()",gBalance.get())
                console.log("gRetornoPorCiclo.get()",gRetornoPorCiclo.get())

                const getProxValor00 = () => localStorage.getItem('proxValor00') 
                const getProxValor01 = () => localStorage.getItem('proxValor01') 
                const getProxValor02 = () => localStorage.getItem('proxValor02') 
                const getProxValor03 = () => localStorage.getItem('proxValor03') 
                const getProxValor10 = () => localStorage.getItem('proxValor10') 
                const getProxValor11 = () => localStorage.getItem('proxValor11') 
                const getProxValor12 = () => localStorage.getItem('proxValor12') 

                globalProxValor00.set(() => getProxValor00() ? parseFloat(getProxValor00()) : globalBalance.get() *  0.002562)
                globalProxValor01.set(() => getProxValor01() ? parseFloat(getProxValor01()) : globalBalance.get() *  0.00362)
                globalProxValor02.set(() => getProxValor02() ? parseFloat(getProxValor02()) : globalBalance.get() *  0.014296)
                globalProxValor03.set(() => getProxValor03() ? parseFloat(getProxValor03()) : globalBalance.get() *  0.041850)
                globalProxValor10.set(() => getProxValor10() ? parseFloat(getProxValor10()) : globalBalance.get() *  0.001497)
                globalProxValor11.set(() => getProxValor11() ? parseFloat(getProxValor11()) : globalBalance.get() *  0.001246)
                globalProxValor12.set(() => getProxValor12() ? parseFloat(getProxValor12()) : globalBalance.get() *  0.00677)
                
                gUser.set(() => user)
                gUser.attach(Persistence('user'))
                gPrevLoc.set(() => 'home')
                readPlan(getUserId(), history)
                history.push('/dashboard', { update: true })
                
            }
            
        ).catch(error => console.log(error.message))
        
    }

    return (
        <div className="header">
            <div></div>
            {/* <div className="header__logoContainer">
                <h1 className="header__logo header__logoO">O</h1>
                <h1 className="header__logo">GERENCIAMENTO</h1>
            </div> */}
            <img src="OGERENCIAMENTO.png" />
            
            

            <div className="header__right">
                <div onClick={signIn} className="header__signIn">ENTRAR</div>
                <div onClick={signIn} className="header__signUp">REGISTRAR</div>
            </div>
            
        </div>
    )
}

export default Header
