import { createState } from '@hookstate/core'
import { readBalance, readHojeTaxa } from './firebaseCRUD'
import firebase from 'firebase'

// const customersRef = firebase.firestore().collection("/customers");
// const userDocRef = customersRef.doc(userId);

// userDocRef.get().then((userDoc) => {   
//     export const data = userDoc.data()
// }).catch((error) => {
//     console.log("Error getting user:", error);
// });  



const getUser = () => localStorage.getItem('user') 
export const globalUser = createState(getUser() ? getUser() : null)

export const getUserId = () => {
    return typeof globalUser === 'string' ? JSON.parse(globalUser)?.user?.uid : 
    typeof globalUser === 'object' ? globalUser?.user?.uid : ""
}

readBalance(getUserId())

//"ccYmU7nQzPTULmHHMJWtfQsZUiw1"
const getBalance = () => localStorage.getItem('balance') 
const globalBalance = createState( parseFloat(getBalance()) ? parseFloat(getBalance()) : parseFloat( readBalance(getUserId())) ? parseFloat( readBalance(getUserId())) : 0)

// const globalBalance = createState( readBalance(getUserId()) ? parseFloat( readBalance(getUserId())) : 
//                                     getBalance()? parseFloat(getBalance()) : 0)

const getInitialBalance = () => localStorage.getItem('initialBalance') 
export const globalInitialBalance = createState(getInitialBalance() ? getInitialBalance() : globalBalance.get())

const getEditedBalance = () => localStorage.getItem('editedBalance') 
export const globalEditedBalance = createState(getEditedBalance() ? getEditedBalance() : globalBalance.get())

const getGanhei = () => localStorage.getItem('ganhei') 
export const globalGanhei = createState(getGanhei() ? parseInt(getGanhei()) : 0)

const getPerdi = () => localStorage.getItem('perdi') 
export const globalPerdi = createState(getPerdi() ? parseInt(getPerdi()) : 0)

const getAcertos = () => localStorage.getItem('acertos') 
export const globalAcertos = createState(getAcertos() ? parseInt(getAcertos()) : 0)

const getCliclos = () => localStorage.getItem('ciclos') 
export const globalCiclos = createState(getCliclos() ? parseInt(getCliclos()) : 0)

const getLucroRestante = () => localStorage.getItem('lucroRestante') 
export const globalLucroRestante = createState(getLucroRestante() ? parseFloat(getLucroRestante()) : 0)

const getOportunidades = () => localStorage.getItem('oportunidades') 
export const globalOportunidades = createState(getOportunidades() ? parseInt(getOportunidades()) : 15)

const getRetornoPorCiclo = () => localStorage.getItem('retornoPorCiclo') 
export const globalRetornoPorCiclo = createState(getRetornoPorCiclo() ? parseFloat(getRetornoPorCiclo()) : parseFloat(0.001497))

const getPayout = () => localStorage.getItem('payout') 
export const globalPayout = createState(getPayout() ? parseFloat(getPayout()) : 0.87)

const getProxValor = () => localStorage.getItem('proxValor') 
export const globalProxValor = createState(getProxValor() ? parseFloat(getProxValor()) : 
                                           globalBalance.get() ? globalRetornoPorCiclo.get() * globalBalance.get()
                                           : globalRetornoPorCiclo.get() *  parseFloat( readBalance(getUserId())))

const getReset = () => localStorage.getItem('reset') 
export const globalReset = createState(getReset() ? getReset() : false)

const getIsEditing = () => localStorage.getItem('isEditing') 
export const globalIsEditing = createState(getIsEditing() ? getIsEditing() : false)

const getBuying = () => localStorage.getItem('buying') 
export const globalBuying = createState(getBuying() ? getBuying() : false)

const getHoje = () => localStorage.getItem('hoje') 
export const globalHoje = createState(getHoje() ? JSON.parse(getHoje()) : {
    valor: 0,
    taxa: -1
})

const getDays = () => localStorage.getItem('days') 
export const globalDays = createState(getDays() ? getDays() : 0)

const getMinimum = () => localStorage.getItem('minimum') 
export const globalMinimum = createState(getMinimum() ? getMinimum() : 780)

const getSemana = () => localStorage.getItem('semana') 
export const globalSemana = createState(getSemana() ? getSemana() : 0)

const getMes = () => localStorage.getItem('mes') 
export const globalMes = createState(getMes() ? getMes() : 0)

const getAno = () => localStorage.getItem('ano') 
export const globalAno = createState(getAno() ? getAno() : 0)

const getPlan = () => localStorage.getItem('plan') 
export const globalPlan = createState(getPlan() ? parseInt(getPlan()) : 0)

const getWins = () => localStorage.getItem('wins') 
export const globalWins = createState(getWins() ? getWins() : [0])

const getLosses = () => localStorage.getItem('losses') 
export const globalLosses = createState(getLosses() ? getLosses() : [0])

const getOption = () => localStorage.getItem('option') 
export const globalOption = createState(getOption() ? parseInt(getOption()) : 0)

const getHojeTaxa = () => localStorage.getItem('hojeTaxa') 
export const globalHojeTaxa = createState(getHojeTaxa() ? parseFloat(getHojeTaxa()) : 0)

const getHistory = () => localStorage.getItem('history') 
export const globalHistory = createState(getHistory() ? JSON.parse(getHistory()) : [])

const getPrevLoc = () => localStorage.getItem('prev-loc') 
export const globalPrevLoc = createState(getPrevLoc() ? getPrevLoc() : null)

const getConnected = () => localStorage.getItem('connected') 
export const globalConnected = createState(getConnected() ? eval(getConnected()) : false)

const getPlay = () => localStorage.getItem('play') 
export const globalPlay = createState(getPlay() ? eval(getPlay()) : false)

const getSubscription = () => localStorage.getItem('subscription') 
export const globalSubscription = createState(getSubscription() ? getSubscription() : null)

const getRoboPerdeu = () => localStorage.getItem('robo_perdeu') 
export const globalRoboPerdeu = createState(getRoboPerdeu() ? getRoboPerdeu() : '0')

const getRoboGanhou = () => localStorage.getItem('robo_ganhou') 
export const globalRoboGanhou = createState(getRoboGanhou() ? getRoboGanhou() : '0')

const getSelectedValue = () => localStorage.getItem('selected_value') 
export const globalSelectedValue = createState(getSelectedValue() ? getSelectedValue() : 'PRACTICE')

export default globalBalance
