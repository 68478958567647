import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { orange } from '@material-ui/core/colors';
import './Dropdown.css'
import { v4 } from 'uuid';
import { useState as useGlobalState } from '@hookstate/core'
import { globalSelectedValue } from './Global';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#35415e',
    fontSize: 12,
    padding: '10px 26px 10px 12px',
    color:"rgb(205, 205, 214)",
    
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: '#35415e',
    }
  },
}))(InputBase);


const DisabledInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#35415e',
      fontSize: 12,
      padding: '10px 26px 10px 12px',
      color:"rgb(205, 205, 214)",
    
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        backgroundColor: '#35415e',
      }
    },
  }))(InputBase);



function Dropdown({ dados, disabled, restaurar, cancelar }) {

    const [value, setValue] = useState({ type: dados[0]?.type, value: dados[0]?.value });
    const gSelectedValue = useGlobalState(globalSelectedValue)

    useEffect(() => {
      const currentValue = localStorage.getItem(dados[0]?.type)
      if (!currentValue) {
        localStorage.setItem(dados[0]?.type, dados[0]?.value)
      } else {
        setValue({type: dados[0]?.type, value: currentValue})
        
      }
    },[])

    useEffect(() => {
      if (restaurar | cancelar) {
        setValue({ type: dados[0]?.type, value: dados[0]?.value })
        localStorage.setItem(dados[0]?.type, dados[0]?.value)
      }
    },[restaurar, cancelar])

    const handleChange = (event) => {
      setValue({type: dados[0]?.type, value: event.target.value});
      localStorage.setItem(dados[0]?.type, event.target.value)
      gSelectedValue.set(() => event.target.value)
    };

    return (
        <>
        {disabled ? 
        <FormControl className="dropdown dropdown__disabled">
           <Select disabled
           labelId="demo-customized-select-label"
           id="demo-customized-select"
           value={value.value}
           onChange={handleChange}
           input={<DisabledInput />}
         >
         
         {dados && dados.map( dado => <MenuItem key={v4()} value={dado.value}>{dado.description}</MenuItem>)}
         </Select>
         </FormControl>
         :
         <FormControl className="dropdown">
         <Select 
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={value.value}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
        
          {dados && dados.map(dado => <MenuItem key={v4()} value={dado.value}>{dado.description}</MenuItem>)}
        </Select>   
        </FormControl>
        } 
        </>
    )
}

export default Dropdown



