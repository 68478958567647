import './NewCard.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import Info from './Info'

import { Persistence } from '@hookstate/persistence';
import { useState as useGlobalState } from '@hookstate/core'
import { auth, provider } from './firebase'
import { globalBuying, globalUser } from './Global'
import { loadStripe } from '@stripe/stripe-js'
import db from './firebase'
import { useState } from 'react'
import Loading from './Loading';


function NewCard({ rows, price, type, description, virgula }) {
    const gUser = useGlobalState(globalUser)
    const gBuying = useGlobalState(globalBuying)

    const getUserId = () => {
        return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
        typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
    }
    const [isLoading, setIsLoading] = useState(false);

    const loadCheckout = async (priceId) => {
        setIsLoading(true);
        const docRef = await db.collection('customers')
        .doc(getUserId())
        .collection("checkout_sessions")
        .add({
            price: priceId,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin
        })

        docRef.onSnapshot(async(snap) => {
            const { error, sessionId } = snap.data()
            if (error) {
                alert(`Ocorreu um erro ao tentar se conectar com o Stripe. Por favor, tente mais tarde.`)
                console.log('erro do stripe',error.message)
            }

            if (sessionId) {
                const stripe = await loadStripe('pk_live_51JOqA3HG3aKHOYzv0u0vZ1vB4rmt4wDv4FL7HZjd7yE3YRxfNpPxR4ebNbjpOlLHsdMzypbllD228YZRLlRbxq2x00cA2pWfNC')
                stripe.redirectToCheckout({ sessionId })
            }
        })
    }

    const signIn = () => {
        gBuying.set(() => true)
        auth.signInWithPopup(provider).then(
            user => {
                gUser.set(() => user)
                gUser.attach(Persistence('user'))
        
               if (price === "49") {
                    loadCheckout('price_1JTpezHG3aKHOYzvpzEJwJ3x')
               }

               if (price === "69") {
                    loadCheckout('price_1JTpgDHG3aKHOYzv7ndEyIqe')
               }

               if (price === "89") {
                    loadCheckout('price_1JnPk6HG3aKHOYzvKPbtPMPX')
               }
            }
            
        ).catch(error => alert(error.message))
    }

    return (
        <div className="newCard">
            <div className="newCard__top">
                <h1>{type}</h1>
                <div className="newCard__priceContainer">
                    <h1>R${price}</h1>
                    <h1 className="newCard__virgula">{virgula}</h1>
                    <span>/mês</span>
                </div>
            </div>
            <div className="newCard__bottom">
                {description && <p>{description}</p>}
                <div className="newCard__rows">

                    {
                    rows.map(row => {
                        return <div className={
                            `newCard__row ${!row.have && 'newCard__rowFalse'}`
                            }>
                        {row.have ? 
                            <CheckCircleOutlineIcon />
                            : 
                            <HighlightOffIcon />
                        }
                        
                        <div className="newCard__rowDescription">
                            <p className={row.premium && "newCard__premium"}>{row.description}</p>
                        {row.infoDesc ?
                            <Info className="info" description={row.infoDesc}>
                                <InfoIcon />
                            </Info> : <></>}
                        </div>

                    </div>
                    })
                    }
                </div>
            </div>
            <div className="newCard__buttonContainer">
                    <div className="newCard__buttton" onClick={signIn}>
                        {isLoading ? "AGUARDE..." : "ASSINAR"}
                        {isLoading && <Loading />}
                    </div>
            </div>
        </div>
    )
}

export default NewCard
