import './TelaConfigRobo.css'
import CloseIcon from '@material-ui/icons/Close';
import CardConfig from './CardConfig'
import SimpleButton from './SimpleButton'
import Dropdown from './Dropdown';
import { pares, timeFrame, velas, estrategia, stopWin, stopLoss, money_type } from './dados'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom' //hook
import EditIcon from '@material-ui/icons/Edit'
import globalBalance, { globalSelectedValue } from './Global';
import { useState as useGlobalState } from '@hookstate/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function TelaConfigRobo({ handleCloseConfig }) {
    const [startDate, setStartDate] = useState(new Date());
    const [signalsTab, setSignalsTab] = useState(false)
    const gBalance = useGlobalState(globalBalance)
    const gSelectedValue = useGlobalState(globalSelectedValue)
    const [sinais, setSinais] = useState('')

    const [warning, setWarning] = useState('')
    const [restaurar, setRestaurar] = useState(false)
    const [cancelar, setCancelar] = useState(false)

    const handleCancelar = async () => {
        await setCancelar(true)
        handleCloseConfig()
        
    }

    const handleRestaurar = () => {
        setRestaurar(true)
    }

    useEffect(() => {
        if (gSelectedValue.get() === 'REAL') {
            setWarning('Atenção! Ao selecionar a opção Dinheiro "REAL" você autoriza o robô a realizar operações automáticas na sua conta real da IQ Option. O Gerenciamento NÃO se responsabiliza, caso ocorram perdas inesperadas de quaisquer valores, seja qual for o motivo que as ocasionou.')
        }else {
            setWarning('')
        }
    },[gSelectedValue.get()])

    useEffect(() => {
        console.log('changed',signalsTab)
    },[signalsTab])


    return (
        <div className="telaConfigRobo">
                <div className="telaConfigRobo__barraSuperior" onClick={handleCloseConfig}>
                    <h1>Configurações do Robô</h1>
                        <CloseIcon/>
                </div>
                <div className="telaConfigRobo__telaInterna">
                    <div className='telaConfigRobo__barraInterna'>
                        <div onClick={() => setSignalsTab(false)} className={signalsTab ? 'telaConfigRobo__barraInternaBin' : 'telaConfigRobo__barraInternaDig'}>
                            <span>Config.</span>
                        </div>

                        <div onClick={() => setSignalsTab(true)} className={!signalsTab ? 'telaConfigRobo__barraInternaBin' : 'telaConfigRobo__barraInternaDig'}>
                            <span>Sinais</span>
                        </div>
                    </div>

                   {
                       !signalsTab ? 
                       <div className="telaConfigRobo__config">
                       <CardConfig idx={1}
                           descricao="Capital Investido"
                           opcoes={gBalance.get()}
                           price
                       />
                       <CardConfig idx={2}
                           descricao="Stop Win"
                           opcoes= {<Dropdown  dados={stopWin} restaurar={restaurar} cancelar={cancelar} />}
                       />
                       <CardConfig idx={3}
                           descricao="Stop Loss"
                           opcoes= {<Dropdown  dados={stopLoss} restaurar={restaurar} cancelar={cancelar} />}
                       />

                       <CardConfig idx={4}
                           descricao="Dinheiro"
                           opcoes= {<Dropdown  dados={money_type} restaurar={restaurar} cancelar={cancelar} />}
                       />
                       <span style={{ color: 'orange'}}>{warning}</span>
                   </div>
                   :
                   <div className="telaConfigRobo__config">
                       
                        <CardConfig idx={5}
                            descricao="Data"
                            opcoes={<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />}
                        />
                        <CardConfig idx={6}
                            descricao="Vela"
                            opcoes= {<Dropdown  dados={velas} restaurar={restaurar} cancelar={cancelar} />}
                        />
                        {/* <CardConfig idx={7}
                            descricao="Timeframe"
                            opcoes= {<Dropdown  dados={timeFrame} restaurar={restaurar} cancelar={cancelar} />}
                        /> */}

                        <CardConfig 
                            className="textArea__container"
                            idx={8}
                            descricao="Sinais"
                            opcoes= {<textarea  placeholder={`14:50;EUR/USD;CALL;5\n16:30;EUR/JPY;PUT;5\n16:50;EUR/GBP;PUT;5`} type='text' value={sinais} onChange={e => setSinais(e.target.value)} className="cardConfig__input cardConfig__inputStyles text-area" />}
                        />
                        <span style={{ color: 'orange'}}>{warning}</span>
                   </div>
                   }

                    <hr className="telaConfigRobo__espaço"/>

                    <div className="telaConfigRobo__buttons">
                      <div className="telaConfigRobo__buttonRestaurar">
                        <SimpleButton nome="Restaurar" onClick={handleRestaurar}/>
                      </div>
                        
                   
                        <div className="telaConfigRobo__buttonsDireita desktop">
                            <SimpleButton className='telaConfigRobo__buttonCancelar' nome="Cancelar" onClick={handleCancelar}/>
                            <SimpleButton nome="Salvar" variant="laranja" onClick={handleCloseConfig}/> 
                        </div>
                   
                        <div className="telaConfigRobo__buttonsDireita mobile">
                            <SimpleButton nome="Salvar" variant="laranja" onClick={handleCloseConfig}/> 
                        </div>
                        
                    </div>
                </div>
            </div>
    )
}

export default TelaConfigRobo
