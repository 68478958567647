import './Card.css'
import Days from './Days'
import globalBalance, { globalDays } from './Global'
import { useState as useGlobalState } from '@hookstate/core'
import React from 'react'


function Card({ title, balance, frequency, compound, description, isCustom }) {
    const percentFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'percent',
        maximumFractionDigits: 2
    })

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })


    const gDays = useGlobalState(globalDays)
    const gBalance = useGlobalState(globalBalance)

    return (
        <div className='card'>
            <div className="card__header">
                <h1 className="card__title">{title}</h1>
                <div className="card__frequency">{frequency}</div>
            </div>

            {isCustom && gDays.get() === 1 ? 
                <div className="card__balance">{formatter.format(gBalance.get())}</div>
            :
                <div className="card__balance">{balance}</div>
            }
            <div className="card__bottom">
                {compound > 0 ?
                <div className="card__compound">+{percentFormatter.format(compound)}</div>
                    :
                <div className="card__negativeCompound">{percentFormatter.format(compound)}</div>
            }
                {isCustom ?
                <div className="card__description card__descriptionDays">Daqui a <Days /> {gDays.get() === 1 ? 'dia' : 'dias'}</div>
                
                :
                <div className="card__description">{description}</div>
                }
                
                
            </div>
        </div>
    )
}

export default React.memo(Card)
