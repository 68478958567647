import './MainDesktop.css'
import ProgressBar from './ProgressBar'

function MainDesktop({ gProxValor, gCiclos, gOportunidades, oportunidades, gAcertos,
    gRetornoPorCiclo, gPayout, acertosPorCiclo, desktopDisplay, ...props }) {
    
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })

    const percentFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'percent',
        maximumFractionDigits: 2
    })

    return <>
        {!desktopDisplay ?
            <div className="mainCard__topLeftOuter">
                    <div className="mainCard__topLeft  mainCard__topLeftProx">
                        <h2>PRÓX. VALOR:</h2>
                        <h1>{formatter.format(gProxValor > 0 ? gProxValor : 0)}</h1>
                    </div>
                    <div className="mainCard__topLeftInner">
                        <div className="mainCard__topLeft">
                            <h2>CICLOS:</h2>
                            <h1>{gCiclos}</h1>
                        </div>
                    </div>
                    <div className="mainCard__topLeftInner">
                    <div className="mainCard__topLeft">
                            <h2>OPORTUNIDADES:</h2>
                            <h1>{`${gOportunidades} de ${oportunidades}`}</h1>
                        </div>
                    </div>

                    <div className="mainCard__topLeftInner">
                        <div className="mainCard__topLeft">
                            <div className="mainCard__topLeftAcertos">
                                
                                <h2>ACERTOS:</h2>
                                {
                                gRetornoPorCiclo === 0.0015 ? <span className="mainCard__green">(NÃO CONSECUTIVOS)</span> 
                                : 
                                gRetornoPorCiclo === 0.0022 ? <span className="mainCard__green">(ACERTO ÚNICO)</span> 
                                : 
                                <span>(CONSECUTIVOS)</span>}
                            </div>
                            
                            <h1>{`${gAcertos} de ${acertosPorCiclo}`}</h1>
                        </div>
                    </div>
                    <div className="mainCard__topLeftInner">
                        <div className="mainCard__topLeft">
                            <div className="mainCard__topLeftRetorno">
                                <h2>RETORNO:</h2>
                                <span>(POR CICLO)</span>
                            </div>
                            <h1>+{percentFormatter.format(gRetornoPorCiclo)}</h1>
                        </div>
                    </div>
                    <div className="mainCard__topLeftInner">
                        <div className="mainCard__topLeft">
                            <h2>PAYOUT:</h2>
                            <h1>+{percentFormatter.format(gPayout)}</h1>
                        </div>
                    </div>
                    
                </div>
            :
        <>
           
        <div className="mainCard__topLeftOuterDesktop">
                    <div className="mainCard__topLeftDesktop  mainCard__topLeftProxDesktop">
                        <h2>PRÓX. VALOR:</h2>
                        <h1>{formatter.format(gProxValor > 0 ? gProxValor : 0)}</h1>
                    </div>
                    
                    {/* <div className="mainCard__outerLeftDesktop">

                        <div className="mainCard__topLeftInnerDesktop">
                            <div className="mainCard__topLeftDesktop">
                                <h2>CICLOS:</h2>
                                <h1>{gCiclos}</h1>
                            </div>
                        </div>
                        <div className="mainCard__topLeftInnerDesktop">
                        <div className="mainCard__topLeftDesktop">
                                <h2>OPORTUNIDADES:</h2>
                                <h1>{`${gOportunidades} de ${oportunidades}`}</h1>
                            </div>
                        </div>

                        <div className="mainCard__topLeftInnerDesktop">
                            <div className="mainCard__topLeftDesktop">
                                <div className="mainCard__topLeftAcertosDesktop">
                                    <h2>ACERTOS:</h2>
                                    <span>(CONSECUTIVOS)</span>
                                </div>
                                
                                <h1>{`${gAcertos} de ${acertosPorCiclo}`}</h1>
                            </div>
                        </div>
                        <div className="mainCard__topLeftInnerDesktop">
                            <div className="mainCard__topLeftDesktop">
                                <div className="mainCard__topLeftRetornoDesktop">
                                    <h2>RETORNO:</h2>
                                    <span>(POR CICLO)</span>
                                </div>
                                <h1>+{percentFormatter.format(gRetornoPorCiclo)}</h1>
                            </div>
                        </div>
                        <div className="mainCard__topLeftInnerDesktop">
                            <div className="mainCard__topLeftDesktop">
                                <h2>PAYOUT:</h2>
                                <h1>+{percentFormatter.format(gPayout)}</h1>
                            </div>
                        </div>

                    </div> */}
                    
                    
                </div>     
        </>}
        </>
        
}

export default MainDesktop
