import './History.css'
import React from 'react';
import db from './firebase'
import { getUserId } from './Global'

function History({ title, trades }) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

    
    return (
        <div className='history'>
            <div className="history__header">
                <h1 className="history__title">{title}</h1>
            </div>
            <div className="history__table">            
                {trades.slice(0,30).map((trade, index) => {
                    
                    return (
                    <div key={index} className="history__tableRow">
                    <div className="history__tableRowLeft">
                        <h3>{trade.time}</h3>
                        <h5>{trade.date}</h5>
                    </div>
                    
                    {trade.result > 0 ? 
                    <div className="history__tableRowRight">
                        <h3>+{formatter.format(trade.result)}</h3>
                        <h5>{formatter.format(trade.invested)}</h5>
                    </div>
                    :
                    <div className="history__tableRowRight">
                        <h3 className="history__tableRowRightLoss">-{formatter.format(Math.abs(trade.result))}</h3>
                        <h5>{formatter.format(trade.invested)}</h5>
                    </div>
                }
                </div>
                )
                })}                
            </div>

        </div>
    )
}

export default React.memo(History)
