export const basico = [
  {
    have: true,
    description: "15 chances - 0,64% por ciclo",
    infoDesc:
      "15 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,64% por ciclo",
  },
  {
    have: true,
    description: "14 chances - 0,90% por ciclo",
    infoDesc:
      "14 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,90% por ciclo",
  },
  {
    have: true,
    description: "10 chances - 3,57% por ciclo",
    infoDesc:
      "10 oportunidades para obter 3 Acertos Consecutivos e Retorno de 3,57% por ciclo",
  },
  {
    have: true,
    description: "7 chances - 10,45% por ciclo",
    infoDesc:
      "7 oportunidades para obter 3 Acertos Consecutivos e Retorno de 10,45% por ciclo",
  },
  {
    have: false,
    description: "20 chances - 3,75% por ciclo",
    infoDesc:
      "20 oportunidades para obter 3 Acertos Consecutivos e Retorno de 3,75% por ciclo",
  },
  {
    have: false,
    description: "30 chances - 0,69% por ciclo",
    infoDesc:
      "30 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,69% por ciclo",
  },
  {
    have: false,
    description: "12 chances - 0,15% por ciclo. 2 Acertos Não Consecutivos",
    infoDesc:
      "12 oportunidades para obter 2 Acertos Não Consecutivos e Retorno de 0,15% por ciclo",
    premium: true,
  },
  {
    have: false,
    description: "8 chances - 0,22% por ciclo. Apenas 1 Acerto",
    infoDesc:
      "8 oportunidades para obter 1 Acerto e Retorno de 0,22% por ciclo",
    premium: true,
  },
  {
    have: false,
    description: "Sessão Operacional de segunda à sexta-feira",
    infoDesc: "Sessão Operacional de segunda à sexta-feira",
    premium: true,
  },
  {
    have: false,
    description: "Robô com operações automáticas",
    infoDesc:
      "Robô executando operações automaticas com o gerenciamento de sua escolha",
    premium: true,
  },
];

export const profissional = [
  {
    have: true,
    description: "15 chances - 0,64% por ciclo",
    infoDesc:
      "15 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,64% por ciclo",
  },
  {
    have: true,
    description: "14 chances - 0,90% por ciclo",
    infoDesc:
      "14 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,90% por ciclo",
  },
  {
    have: true,
    description: "10 chances - 3,57% por ciclo",
    infoDesc:
      "10 oportunidades para obter 3 Acertos Consecutivos e Retorno de 3,57% por ciclo",
  },
  {
    have: true,
    description: "7 chances - 10,45% por ciclo",
    infoDesc:
      "7 oportunidades para obter 3 Acertos Consecutivos e Retorno de 10,45% por ciclo",
  },
  {
    have: true,
    description: "20 chances - 3,75% por ciclo",
    infoDesc:
      "20 oportunidades para obter 3 Acertos Consecutivos e Retorno de 3,75% por ciclo",
  },
  {
    have: true,
    description: "30 chances - 0,69% por ciclo",
    infoDesc:
      "30 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,69% por ciclo",
  },
  {
    have: true,
    description: "12 chances - 0,15% por ciclo. 2 Acertos Não Consecutivos",
    infoDesc:
      "12 oportunidades para obter 2 Acertos Não Consecutivos e Retorno de 0,15% por ciclo",
    premium: true,
  },
  {
    have: true,
    description: "8 chances - 0,22% por ciclo. Apenas 1 Acerto",
    infoDesc:
      "8 oportunidades para obter 1 Acerto e Retorno de 0,22% por ciclo",
    premium: true,
  },
  {
    have: true,
    description: "Sessão Operacional de segunda à sexta-feira",
    infoDesc: "Sessão Operacional de segunda à sexta-feira",
    premium: true,
  },
  {
    have: false,
    description: "Robô com operações automáticas",
    infoDesc:
      "Robô executando operações automaticas com o gerenciamento de sua escolha",
    premium: true,
  },
];

export const automatizado = [
  {
    have: true,
    description: "15 chances - 0,64% por ciclo",
    infoDesc:
      "15 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,64% por ciclo",
  },
  {
    have: true,
    description: "14 chances - 0,90% por ciclo",
    infoDesc:
      "14 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,90% por ciclo",
  },
  {
    have: true,
    description: "10 chances - 3,57% por ciclo",
    infoDesc:
      "10 oportunidades para obter 3 Acertos Consecutivos e Retorno de 3,57% por ciclo",
  },
  {
    have: true,
    description: "7 chances - 10,45% por ciclo",
    infoDesc:
      "7 oportunidades para obter 3 Acertos Consecutivos e Retorno de 10,45% por ciclo",
  },
  {
    have: true,
    description: "20 chances - 3,75% por ciclo",
    infoDesc:
      "20 oportunidades para obter 3 Acertos Consecutivos e Retorno de 3,75% por ciclo",
  },
  {
    have: true,
    description: "30 chances - 0,69% por ciclo",
    infoDesc:
      "30 oportunidades para obter 3 Acertos Consecutivos e Retorno de 0,69% por ciclo",
  },
  {
    have: true,
    description: "12 chances - 0,15% por ciclo. 2 Acertos Não Consecutivos",
    infoDesc:
      "12 oportunidades para obter 2 Acertos Não Consecutivos e Retorno de 0,15% por ciclo",
    premium: true,
  },
  {
    have: true,
    description: "8 chances - 0,22% por ciclo. Apenas 1 Acerto",
    infoDesc:
      "8 oportunidades para obter 1 Acerto e Retorno de 0,22% por ciclo",
    premium: true,
  },
  {
    have: true,
    description: "Sessão Operacional de segunda à sexta-feira",
    infoDesc: "Sessão Operacional de segunda à sexta-feira",
    premium: true,
  },
  {
    have: true,
    description: "Robô com operações automáticas",
    infoDesc:
      "Robô executando operações automaticas com o gerenciamento de sua escolha",
    premium: true,
  },
];
