import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import BatteryCharging30Icon from '@material-ui/icons/BatteryCharging30';
import BatteryCharging60Icon from '@material-ui/icons/BatteryCharging60';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import './Basic.css'
import { globalOption, globalPlan, globalPlay } from './Global'
import { useState as useGlobalState } from '@hookstate/core'
import { updateDataSlice } from './firebaseCRUD';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#FF7700',
    },
  },
});

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function CenteredTabs() {
  const gPlan = useGlobalState(globalPlan)
  const gOption = useGlobalState(globalOption)
  const gPlay = useGlobalState(globalPlay)

  const handleChange = (event, newValue) => {
    gPlay.set(() => false)
    gOption.set(() => newValue)
    localStorage.setItem('option',gOption.get())
    const uid = localStorage.getItem("uid")
    updateDataSlice(uid, { option: gOption.get() })
    
  };

  // useEffect(() => {
  //   const optionStorage = parseInt(localStorage.getItem("option"))
  //   gOption.set(() =>  optionStorage ? optionStorage : 0)
  //   const uid = localStorage.getItem("uid")
  //   updateDataSlice(uid, { option: gOption.get() })
    
  // }, [gPlan.get()])

  return (
    <div className='basic'>
    <MuiThemeProvider theme={theme}>
    {gPlan.get() === 0 ? 

      <AppBar position="static" color="default">
        <Tabs
          value={gOption.get()}
          onChange={handleChange}
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
          centered
          
        >
          <Tab label="0.64%"  icon={<BatteryCharging20Icon />} {...a11yProps(0)} />
          <Tab label="0.90%" icon={<BatteryCharging30Icon />} {...a11yProps(1)} />
          <Tab label="3.57%" icon={<BatteryCharging60Icon />} {...a11yProps(2)} />
          <Tab label="10.45%" icon={<BatteryChargingFullIcon />} {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      :

      <AppBar position="static" color="default">
        <Tabs
          value={gOption.get()}
          onChange={handleChange}
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
          centered
          
        >
          <Tab label="0.15%"  icon={<BatteryCharging20Icon />} {...a11yProps(0)} />
          <Tab label="0.69%" icon={<BatteryCharging30Icon />} {...a11yProps(1)} />
          <Tab label="3.75%" icon={<BatteryCharging80Icon />} {...a11yProps(2)} />
          <Tab label="0.22%" icon={<BatteryCharging20Icon />} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
    }
      
    </MuiThemeProvider>
    </div>
  );
}
