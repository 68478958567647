import './BarChart.css'
import { Bar } from 'react-chartjs-2'
import { useState as useGlobalState } from '@hookstate/core'
import { globalEditedBalance, globalHojeTaxa, globalReset } from './Global'
import React, { useState, useEffect } from 'react'

const jurosCompostos = ( valorInicial, taxa, vezes, periodo ) => {
    let valorFinal = valorInicial * (1+taxa) ** (vezes * periodo)
    let taxaFinal = (1+taxa) ** (vezes * periodo)
    return {
        valor: valorFinal.toFixed(2),
        taxa: (taxaFinal-1)*100,
    }
}

function BarChart({ title }) {
    const gEditedBalance = useGlobalState(globalEditedBalance)
    const gHojeTaxa = useGlobalState(globalHojeTaxa)
    const gReset = useGlobalState(globalReset)

    // const [v30, setV30] = useState(0)
    // const [v60, setV60] = useState(0)
    // const [v90, setV90] = useState(0)
    // const [v120, setV120] = useState(0)
    // const [v150, setV150] = useState(0)
    // const [v180, setV180] = useState(0)
    // const [v210, setV210] = useState(0)
    // const [v240, setV240] = useState(0)
    // const [v270, setV270] = useState(0)
    // const [v300, setV300] = useState(0)
    // const [v330, setV330] = useState(0)
    // const [v365, setV365] = useState(0)

    const [values, setValues] = useState([])
    const [decimal, setDecimal] = useState('')

    useEffect(() => {
        setDecimal('')
        setValues([])
    },[gReset.get()])

    

    useEffect(() => {
        console.log('gHojeTaxa.get()',gHojeTaxa.get())
        let v30 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 30) 
        let v60 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 60) 
        let v90 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 90) 
        let v120 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 120) 
        let v150 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 150) 
        let v180 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 180) 
        let v210 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 210) 
        let v240 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 240) 
        let v270 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 270) 
        let v300 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 300) 
        let v330 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 330) 
        let v365 = jurosCompostos(gEditedBalance.get(), gHojeTaxa.get(), 1, 365) 
        

        if (v365.taxa > 1000) {
            let divisor = 1000
            setDecimal('mil ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }
    
        if (v365.taxa > 10000) {
            let divisor = 10000
            setDecimal('10 mil ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }
    
        if (v365.taxa > 100000) {
            let divisor = 100000
            setDecimal('100 mil ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
            
        }
    
        if (v365.taxa > 1000000) {
            let divisor = 1000000
            setDecimal('milhão ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }

        if (v365.taxa > 10000000) {
            let divisor = 10000000
            setDecimal('10 milhões ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }

        if (v365.taxa > 100000000) {
            let divisor = 100000000
            setDecimal('100 milhões ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }

        if (v365.taxa > 1000000000) {
            let divisor = 1000000000
            setDecimal('1 bilhão ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }

        if (v365.taxa > 10000000000) {
            let divisor = 10000000000
            setDecimal('10 bilhões ')
            setValues([v30.taxa/divisor,
             v60.taxa/divisor,
             v90.taxa/divisor,
             v120.taxa/divisor,
             v150.taxa/divisor,
             v180.taxa/divisor,
             v210.taxa/divisor,
             v240.taxa/divisor,
             v270.taxa/divisor,
             v300.taxa/divisor,
             v330.taxa/divisor,
             v365.taxa/divisor])
        }

        if (v365.taxa > 100000000000) {
            setDecimal('Mais de 100 bilhões ')
            setValues([])
        }

        if (v365.taxa <= 1000) {
            setDecimal('')
            setValues([v30.taxa, v60.taxa, v90.taxa, v120.taxa, v150.taxa, v180.taxa, v210.taxa, v240.taxa, v270.taxa, v300.taxa, v330.taxa, v365.taxa])
        }
        
    },[gHojeTaxa.get()])   

    const meses = {
        0: 'Jan',
        1: 'Fev',
        2: 'Mar',
        3: 'Abr',
        4: 'Mai',
        5: 'Jun',
        6: 'Jul',
        7: 'Ago',
        8: 'Set',
        9: 'Out',
        10: 'Nov',
        11: 'Dez'
    }

    const today = new Date();
    let esseMes = today.getMonth()
    let mesAtual = esseMes+1
    let count = 0
    let ordemDosMeses = []
    while (count < 12) {
        if ((mesAtual < 12) || (mesAtual < esseMes)) {
            ordemDosMeses.push(meses[mesAtual])
            if (mesAtual === 11) {
                mesAtual = 0
            }else {
                mesAtual += 1
            }
            
        }
        count += 1
    }


    

    const data = {
        
        labels: ordemDosMeses,
             datasets: [
            {
                label: `Saldo Esperado por Mês (${decimal}%) `,
                data: values,
           
                backgroundColor:[
                    '#3ed89b',
                    '#33dc96',
                    '#26e091',
                    '#16e48b',
                    '#00e784',
                    '#00eb7d',
                    '#00ef75',
                    '#00f26c',
                    '#00f561',
                    '#00f956',
                    '#00fc49',
                    '#01ff38'
            ]
            }
        ]
    }

    const options = {
        plugins: {
            legend: {
                labels: {
                    color: "rgba(255, 255, 255, 0.5)",
                    font: {
                        size: 12 
                      }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: 'rgba(255,255,255,0.5)',
                    font: {
                        size: 12
                      }
                    
                },
                grid: {
                    color: 'rgba(255,255,255,1)',
                    display: false
                }
            },
            y: {
                ticks: {
                    color: 'rgba(255,255,255,0.5)',
                    font: {
                        size: 12
                      },
                      stepSize: 4,
                      beginAtZero: true
                },
                grid: {
                    color: 'rgba(255,255,255,1)',
                    display: false
                }
            } 
        }
        
      }
    
    return (
        <div className='barChart'>
            <div className="barChart__header">
                <h1 className="barChart__title">{title}</h1>
            </div>

            <div  className='barChart__chart'>
            <Bar data={data} options={options}/>
            </div>

        </div>
    )
}

export default React.memo(BarChart)
