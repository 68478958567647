import './DoughnutChart.css'
import { Doughnut } from 'react-chartjs-2'
import React from 'react';   

function DoughnutChart({ title, wins, losses }) {
   
    const data = {
        labels: ['Acertos', 'Erros'],
        datasets: [
            {
                data: [wins, losses],
                backgroundColor:[
                    'rgba(0, 255, 0, 0.65)',
                    'rgba(255, 30, 30, 0.8)',
            ],
            text: '51,3%',
            borderColor: "#35415e"
            }
        ]
    }

    const options = {
        plugins: {
            legend: {
                labels: {
                    color: "rgba(255, 255, 255, 0.5)"
                }
            }
        }
      }

    return (
        
        <div className='doughnutChart'>
            <div className="doughnutChart__header">
                <h1 className="doughnutChart__title">{title}</h1>
            </div>

            <div  className='doughnutChart__chart'>
            <Doughnut data={data} options={options} />
            </div>

            <div className="doughnutChart__table">
                <div className="doughnutChart__tableHeader">
                    <h1>Métrica</h1>
                    <h1>Quantidade</h1>
                    <h1>Percentual</h1>
                </div>
                <div className="doughnutChart__tableRow">
                    <p>Acertos</p>
                    <p>{wins}</p>
                    <h2  className='doughnutChart__tableRowWins'>+{wins/(wins+losses)*100 > 0 ? Math.round(wins/(wins+losses)*100) : 0}%</h2>
                </div>
                <div className="doughnutChart__tableRow">
                    <p>Erros</p>
                    <p>{losses}</p>
                    <h2 className='doughnutChart__tableRowLosses'>-{losses/(wins+losses)*100 > 0 ? Math.round(losses/(wins+losses)*100) : 0}%</h2>
                </div>
                
            </div>

        </div>
    )
}

export default React.memo(DoughnutChart)

