import { createState } from '@hookstate/core'
import { readBalance } from './firebaseCRUD'
import globalBalance, { getUserId } from './Global'

const getGanhei = () => localStorage.getItem('ganhei02') 
export const globalGanhei = createState(getGanhei() ? parseInt(getGanhei()) : 0)

const getPerdi = () => localStorage.getItem('perdi02') 
export const globalPerdi = createState(getPerdi() ? parseInt(getPerdi()) : 0)

const getAcertos = () => localStorage.getItem('acertos02') 
export const globalAcertos = createState(getAcertos() ? parseInt(getAcertos()) : 0)

const getCliclos = () => localStorage.getItem('ciclos02') 
export const globalCiclos = createState(getCliclos() ? parseInt(getCliclos()) : 0)

const getLucroRestante = () => localStorage.getItem('lucroRestante02') 
export const globalLucroRestante = createState(getLucroRestante() ? parseFloat(getLucroRestante()) : 0)

const getOportunidades = () => localStorage.getItem('oportunidades02') 
export const globalOportunidades = createState(getOportunidades() ? parseInt(getOportunidades()) : 10)

const getRetornoPorCiclo = () => localStorage.getItem('retornoPorCiclo02') 
export const globalRetornoPorCiclo = createState(getRetornoPorCiclo() ? parseFloat(getRetornoPorCiclo()) : parseFloat(0.0357))

const getProxValor = () => localStorage.getItem('proxValor02') 
export const globalProxValor = createState(getProxValor() ? parseFloat(getProxValor()) : 
                                           globalBalance.get() ? globalRetornoPorCiclo.get() * globalBalance.get()
                                           : globalRetornoPorCiclo.get() *  parseFloat( readBalance(getUserId())))
