import db from './firebase'
import { updateDataSlice } from './firebaseCRUD'

import globalBalance, { 
    globalPlan, globalOption, globalHojeTaxa, globalGanhei, globalPerdi, getUserId, globalHistory, globalHoje, globalProxValor, globalEditedBalance
 } from './Global'

import {
        globalAcertos as globalAcertos00, 
        globalCiclos as globalCiclos00, 
        globalOportunidades as globalOportunidades00, 
        globalProxValor as globalProxValor00,
        globalLucroRestante as globalLucroRestante00 } from './Global00'

import {
    globalAcertos as globalAcertos01, 
    globalCiclos as globalCiclos01, 
    globalOportunidades as globalOportunidades01, 
    globalProxValor as globalProxValor01,
    globalLucroRestante as globalLucroRestante01 } from './Global01'

import {
    globalAcertos as globalAcertos02, 
    globalCiclos as globalCiclos02, 
    globalOportunidades as globalOportunidades02, 
    globalProxValor as globalProxValor02,
    globalLucroRestante as globalLucroRestante02 } from './Global02'

import {
    globalAcertos as globalAcertos03, 
    globalCiclos as globalCiclos03, 
    globalOportunidades as globalOportunidades03, 
    globalProxValor as globalProxValor03,
    globalLucroRestante as globalLucroRestante03 } from './Global03'

import {
    globalAcertos as globalAcertos10, 
    globalCiclos as globalCiclos10, 
    globalOportunidades as globalOportunidades10, 
    globalProxValor as globalProxValor10,
    globalLucroRestante as globalLucroRestante10} from './Global10'


import {
    globalAcertos as globalAcertos11, 
    globalCiclos as globalCiclos11, 
    globalOportunidades as globalOportunidades11, 
    globalProxValor as globalProxValor11,
    globalLucroRestante as globalLucroRestante11 } from './Global11'

import {
    globalAcertos as globalAcertos12, 
    globalCiclos as globalCiclos12, 
    globalOportunidades as globalOportunidades12, 
    globalProxValor as globalProxValor12,
    globalLucroRestante as globalLucroRestante12 } from './Global12'


import {
    globalAcertos as globalAcertos13, 
    globalCiclos as globalCiclos13, 
    globalOportunidades as globalOportunidades13, 
    globalProxValor as globalProxValor13,
    globalLucroRestante as globalLucroRestante13 } from './Global13'

// const userId = localStorage.getItem("uid")

// const deleteTrades = (userId) => {
//     const tradesRef = db.collection('customers').doc(userId).collection('trades')
//     tradesRef.onSnapshot(snapshot => {
//         snapshot.docs.forEach(doc => {
//             tradesRef.doc(doc.id).delete()
//             .catch(error => {
//                 console.log(error)
//             })
//         })
//     })
// }


export const resetProxValor = () => {
    globalProxValor00.set(() => globalBalance.get() *  0.002562)
    globalProxValor01.set(() => globalBalance.get() *  0.00362)
    globalProxValor02.set(() => globalBalance.get() *  0.014296)
    globalProxValor03.set(() => globalBalance.get() *  0.041850)
    globalProxValor10.set(() => globalBalance.get() *  0.001497)
    globalProxValor11.set(() => globalBalance.get() *  0.001246)
    globalProxValor12.set(() => globalBalance.get() *  0.00677)
    globalProxValor13.set(() => globalBalance.get() *  0.0025285) 
}

const resetData = (userId) => {
    globalHistory.set(() => [])
    localStorage.setItem('history',globalHistory.get())
    
    const tradesRef = db.collection('customers').doc(userId).collection('trades')
    console.log(tradesRef)

    globalBalance.set(() => 0)
    localStorage.setItem('balance', 0)

    globalHojeTaxa.set(() => 0)
    globalEditedBalance.set(() => globalBalance.get() || 0)
    
    globalPlan.set(() => 0)
    globalOption.set(() => 0)

    globalGanhei.set(() => 0)
    globalPerdi.set(() => 0)

    globalAcertos00.set(() => 0)
    globalCiclos00.set(() => 0)
    globalOportunidades00.set(() => 15)
    globalProxValor00.set(() => 0)//globalBalance.get() *  0.002562)
    globalLucroRestante00.set(() => 0)

    
    globalAcertos01.set(() => 0)
    globalCiclos01.set(() => 0)
    globalOportunidades01.set(() => 14)
    globalProxValor01.set(() => 0)//globalBalance.get() *  0.00362)
    globalLucroRestante01.set(() => 0)

    
    globalAcertos02.set(() => 0)
    globalCiclos02.set(() => 0)
    globalOportunidades02.set(() => 10)
    globalProxValor02.set(() => 0)//globalBalance.get() *  0.014296)
    globalLucroRestante02.set(() => 0)

    
    globalAcertos03.set(() => 0)
    globalCiclos03.set(() => 0)
    globalOportunidades03.set(() => 7)
    globalProxValor03.set(() => 0)//globalBalance.get() *  0.041850)
    globalLucroRestante03.set(() => 0)

    
    globalAcertos10.set(() => 0)
    globalCiclos10.set(() => 0)
    globalOportunidades10.set(() => 12)
    globalProxValor10.set(() => 0)//globalBalance.get() *  0.001497)
    globalLucroRestante10.set(() => 0)

    
    globalAcertos11.set(() => 0)
    globalCiclos11.set(() => 0)
    globalOportunidades11.set(() => 30)
    globalProxValor11.set(() => 0)//globalBalance.get() *  0.001246)
    globalLucroRestante11.set(() => 0)

    
    globalAcertos12.set(() => 0)
    globalCiclos12.set(() => 0)
    globalOportunidades12.set(() => 20)
    globalProxValor12.set(() => 0)//globalBalance.get() *  0.00677)
    globalLucroRestante12.set(() => 0)

    globalAcertos13.set(() => 0)
    globalCiclos13.set(() => 0)
    globalOportunidades13.set(() => 8)
    globalProxValor13.set(() => 0)//globalBalance.get() *  ???)
    globalLucroRestante13.set(() => 0)


    const resetObj = {
        plan: 0,
        option: 0,
        ganhei: 0,
        perdi: 0,
        editedBalance: globalBalance.get() || 0,
        acertos00: 0,
        ciclos00: 0,
        lucroRestante00: 0,
        oportunidades00: globalOportunidades00.get(),
        proxValor00: globalProxValor00.get(),
        acertos01: 0,
        ciclos01: 0,
        lucroRestante01: 0,
        oportunidades01: globalOportunidades01.get(),
        proxValor01: globalProxValor01.get(),
        acertos02: 0,
        ciclos02: 0,
        lucroRestante02: 0,
        oportunidades02: globalOportunidades02.get(),
        proxValor02: globalProxValor02.get(),
        acertos03: 0,
        ciclos03: 0,
        lucroRestante03: 0,
        oportunidades03: globalOportunidades03.get(),
        proxValor03: globalProxValor03.get(),
        acertos10: 0,
        ciclos10: 0,
        lucroRestante10: 0,
        oportunidades10: globalOportunidades10.get(),
        proxValor10: globalProxValor10.get(),
        acertos11: 0,
        ciclos11: 0,
        lucroRestante11: 0,
        oportunidades11: globalOportunidades11.get(),
        proxValor11: globalProxValor11.get(),
        acertos12: 0,
        ciclos12: 0,
        lucroRestante12: 0,
        oportunidades12: globalOportunidades12.get(),
        proxValor12: globalProxValor12.get(),
        acertos13: 0,
        ciclos13: 0,
        lucroRestante13: 0,
        oportunidades13: globalOportunidades13.get(),
        proxValor13: globalProxValor13.get(),
        }

    parseInt(localStorage.setItem('plan',0))
    parseInt(localStorage.setItem('option',0))

    parseFloat(localStorage.setItem('ganhei',0))
    parseFloat(localStorage.setItem('perdi',0))
    parseFloat(localStorage.setItem('editedBalance',globalBalance.get() || 0))

    parseFloat(localStorage.setItem('acertos00',0))
    parseFloat(localStorage.setItem('ciclos00',0))
    parseFloat(localStorage.setItem('lucroRestante00',0))
    parseFloat(localStorage.setItem('oportunidades00',globalOportunidades00.get()))
    parseFloat(localStorage.setItem('proxValor00',globalProxValor00.get()))

    
    parseFloat(localStorage.setItem('acertos01',0))
    parseFloat(localStorage.setItem('ciclos01',0))
    parseFloat(localStorage.setItem('lucroRestante01',0))
    parseFloat(localStorage.setItem('oportunidades01',globalOportunidades01.get()))
    parseFloat(localStorage.setItem('proxValor01',globalProxValor01.get()))

    
    parseFloat(localStorage.setItem('acertos02',0))
    parseFloat(localStorage.setItem('ciclos02',0))
    parseFloat(localStorage.setItem('lucroRestante02',0))
    parseFloat(localStorage.setItem('oportunidades02',globalOportunidades02.get()))
    parseFloat(localStorage.setItem('proxValor02',globalProxValor02.get()))

    
    parseFloat(localStorage.setItem('acertos03',0))
    parseFloat(localStorage.setItem('ciclos03',0))
    parseFloat(localStorage.setItem('lucroRestante03',0))
    parseFloat(localStorage.setItem('oportunidades03',globalOportunidades03.get()))
    parseFloat(localStorage.setItem('proxValor03',globalProxValor03.get()))

    
    parseFloat(localStorage.setItem('acertos10',0))
    parseFloat(localStorage.setItem('ciclos10',0))
    parseFloat(localStorage.setItem('lucroRestante10',0))
    parseFloat(localStorage.setItem('oportunidades10',globalOportunidades10.get()))
    parseFloat(localStorage.setItem('proxValor10',globalProxValor10.get()))

    
    parseFloat(localStorage.setItem('acertos11',0))
    parseFloat(localStorage.setItem('ciclos11',0))
    parseFloat(localStorage.setItem('lucroRestante11',0))
    parseFloat(localStorage.setItem('oportunidades11',globalOportunidades11.get()))
    parseFloat(localStorage.setItem('proxValor11',globalProxValor11.get()))

    
    parseFloat(localStorage.setItem('acertos12',0))
    parseFloat(localStorage.setItem('ciclos12',0))
    parseFloat(localStorage.setItem('lucroRestante12',0))
    parseFloat(localStorage.setItem('oportunidades12',globalOportunidades12.get()))
    parseFloat(localStorage.setItem('proxValor12',globalProxValor12.get()))

    parseFloat(localStorage.setItem('acertos13',0))
    parseFloat(localStorage.setItem('ciclos13',0))
    parseFloat(localStorage.setItem('lucroRestante13',0))
    parseFloat(localStorage.setItem('oportunidades13',globalOportunidades13.get()))
    parseFloat(localStorage.setItem('proxValor13',globalProxValor13.get()))

    


updateDataSlice(userId,resetObj)

}

export default resetData





