import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import balanceReducer from '../features/balanceSlice';
import softwareReducer from '../features/softwareSlice';


export const store = configureStore({
  reducer: {
    user: userReducer,
    balance: balanceReducer,
    software: softwareReducer
  },
});
