import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './SelectPlan.css'
import { globalDays } from './Global'
import { useState as useGlobalState } from '@hookstate/core'
import './Days.css'
import { v4 } from 'uuid'

function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

export default function SimpleSelect() {
  const gDays = useGlobalState(globalDays)
  
  const days = range(0,366) 

  const handleChange = (event) => {
    gDays.set( () => event.target.value)
  }

  return (
    <div className='days'>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gDays.get()}
          onChange={handleChange}
        >
          {days.map(day => {
              return <MenuItem key={v4()} value={day}>{day}</MenuItem>
          })}
   
        </Select>
      </FormControl>
    </div>
  );
}


