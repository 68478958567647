import './TelaLoginRobo.css'
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'
import CryptoJS from 'crypto-js'
import { updateDataSlice } from './firebaseCRUD';
import { useState as useGlobalState } from '@hookstate/core'
import { globalRoboGanhou, globalRoboPerdeu, globalConnected } from './Global'

function TelaLoginRobo({ handleCloseLogin}) {

    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [resp, setResp] = useState()
    const [error, setError] = useState('')
    const userId = JSON.parse(localStorage.getItem('user'))
    const gRoboPerdeu = useGlobalState(globalRoboPerdeu)
    const getRoboGanhou = useGlobalState(globalRoboGanhou)
    const gConnected = useGlobalState(globalConnected)


    const handleSubmit = (e) => {
        e.preventDefault()
        
        const data = [{ email: email, password : password }]

        // Encrypt
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'DontTellAnyoneText').toString();
        //log encrypted data
        localStorage.setItem('ciphertext',ciphertext)

        setLoading(true)       

        //
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        };
        fetch(`http://localhost:5000/users/${userId.user.uid}`, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
    
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
    
                setResp(data)
                console.log('data',data)
                 
                if (data.success) {
                    gConnected.set(() => true)
                    localStorage.setItem('connected', true)
                    localStorage.setItem('roboEmail', email)
                    setLoading(false)
                    setSent(true)
                    handleCloseLogin()
                    updateDataSlice(userId.user.uid, { robo_perdeu: '0', robo_ganhou: '0' })
                    
                } else {
                    setError(data.msg)
                    setLoading(false)
                    setPassword('')
                    gConnected.set(() => false)
                    localStorage.setItem('connected', false)
                }
            })
            .catch(error => {
                setResp({ errorMessage: error.toString() });
                setError('Erro ao conectar-se a IQ Option. Certifique-se de ter feito o download do arquivo "robo-18-jan-22.exe" e de tê-lo executado ',error)
                setLoading(false)
            });
        //            
        
      }

    //   useEffect(() => {
    //           setLoading(false)
    //   },[sent])

    return (
        <div className="telaLoginRobo">
          <IconButton onClick={handleCloseLogin} style={{ color: 'white', position: 'absolute', top: '5px', right: '5px', }}>
            <CloseIcon />
          </IconButton>

          <div className="telaLoginRobo__header">
                <div className="telaLoginRobo__headerTop">
                    <h1>Conecte-se a IQ Option</h1>
                </div>
                <span>Entre com suas credenciais para operar automaticamente.</span>
            </div>
            {/* <div className="telaLoginRobo__header" onClick={handleCloseLogin}>
                    <h1>Conecte-se a IQ Option</h1>
                        <CloseIcon/>
            </div> */}

            <form color="warning" onSubmit={handleSubmit}>

                <div className="telaLoginRobo__labels">
                    <p>Email:</p>
                    <input type="text" value={email} placeholder="Email" onChange={e => setEmail(e.target.value)} />

                    <p>Senha:</p>
                    <input type="password" value={password} placeholder="Senha" onChange={e => setPassword(e.target.value)} />
                </div>
                <span className='telaLoginRobo__error'>{error}</span>

                <div className="telaLoginRobo__button">
                    <Button  variant="contained" style={{ backgroundColor: '#35415e', color: 'white', flex: 0.48 }} onClick={handleCloseLogin} >
                        Cancelar
                    </Button>
                                        
                    <Button  variant="contained" style={{ flex: 0.48 }} color="primary" type="submit" onClick={handleSubmit}>
                    {sent ? 'Carregado!' : 
                    loading ? 'Carregando...'  : 
                    'Entrar'}
                    
                    {sent && <CheckCircleIcon/>}
                    {loading && <CircularProgress size={20} />}
                    </Button>

                </div>

            </form>

        </div>
    )
}

export default TelaLoginRobo