export const pares = [
    {
        description: 'EUR/USD',
        value: 'EURUSD'
    },
    {
        description: 'EUR/GBP',
        value: 'EURGBP'
    },
    {
        description: 'EUR/AUD',
        value: 'EURAUD'
    }
]

export const money_type = [
    {
        type: 'money_type',
        description: 'REAL',
        value: 'REAL'
    },
    {
        type: 'money_type',
        description: 'FICTÍCIO',
        value: 'PRACTICE'
    }
]

export const timeFrame = [
    {
        description: '2 min',
        value: '2min'
    },
    {
        description: '5 min',
        value: '5min'
    },
    {
        description: '15 min',
        value: '15min'
    },
    {
        description: '30 min',
        value: '30min'
    }
]

export const velas = [
    {
        description: '1 min',
        value: '1min'
    },
    {
        description: '2 min',
        value: '2min'
    },
    {
        description: '5 min',
        value: '5min'
    },
    {
        description: '10 min',
        value: '10min'
    },
    {
        description: '15 min',
        value: '15min'
    },
    {
        description: '30 min',
        value: '30min'
    }
]

export const estrategia = [
    {
        description: 'MACD',
        value: 'MACD'
    }
]

export const stopWin = [
    {   
        type: 'stopWin',
        description: '0,64%',
        value: 0.64
    },
    {   
        type: 'stopWin',
        description: '0,90%',
        value: 0.9
    },
    {   
        type: 'stopWin',
        description: '3,57%',
        value: 3.57
    },
    {   
        type: 'stopWin',
        description: '10,45%',
        value: 10.45
    },
    {   
        type: 'stopWin',
        description: '0,15%',
        value: 0.15
    },
    {   
        type: 'stopWin',
        description: '0,69%',
        value: 0.69
    },
    {   
        type: 'stopWin',
        description: '3,75%',
        value: 3.75
    },
    {   
        type: 'stopWin',
        description: '0,22%',
        value: 0.22
    }
]


export const stopLoss = [
    {
        type: 'stopLoss',
        description: '1 perda',
        value: 1
    },
    {
        type: 'stopLoss',
        description: '2 perdas',
        value: 2
    },
    {
        type: 'stopLoss',
        description: '3 perdas',
        value: 3
    },

    {
        type: 'stopLoss',
        description: '4 perdas',
        value: 4
    },

    {
        type: 'stopLoss',
        description: '5 perdas',
        value: 5
    },
    
]