import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './SelectPlan.css'
import { getUserId, globalPlan, globalSubscription } from './Global'
import { useState as useGlobalState } from '@hookstate/core'
import { updateDataSlice } from './firebaseCRUD';
import { v4 } from 'uuid'

function SimpleSelect() {
  const gPlan = useGlobalState(globalPlan)
  const gSubscription = useGlobalState(globalSubscription)

  const handleChange = (event) => {
    const uid = localStorage.getItem("uid")
    gPlan.set( () => event.target.value)
    localStorage.setItem('plan',gPlan.get())
    updateDataSlice(uid, { plan: gPlan.get() })
  }

  const plans = [
    {
      title: "Básico",
      value: 0
    },
    {
      title: "Profissional",
      value: 1
    }
  ]

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gPlan.get() ? parseInt(gPlan.get()) : 0 }
          onChange={handleChange}
        >
          {gSubscription.get() === "Profissional" || gSubscription.get() === "Automatizado" ?
            plans.map(plan => {
              return <MenuItem key={v4()} value={plan.value}>{plan.title}</MenuItem> 
            })
          :
          <MenuItem key={v4()} value={0}>Básico</MenuItem>   
          }
        </Select>
      </FormControl>
    </div>
  );
}

export default React.memo(SimpleSelect)

