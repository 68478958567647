import './CardConfig.css';
import { useState, useRef, useEffect } from 'react'
import { useState as useGlobalState } from '@hookstate/core';
import EditIcon from '@material-ui/icons/Edit'
import { updateBalance, updateDataSlice } from './firebaseCRUD'
import globalBalance, { globalReset, 
    globalInitialBalance, 
    globalEditedBalance,
    globalUser,
    globalIsEditing,
  } from './Global'

const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    
})

function CardConfig({descricao, opcoes, idx, price, disabled, className }) {
    const gBalance = useGlobalState(globalBalance)
    const gReset = useGlobalState(globalReset)
    const gInitialBalance = useGlobalState(globalInitialBalance)
    const gEditedBalance = useGlobalState(globalEditedBalance)
    const gUser = useGlobalState(globalUser)
    const gIsEditing = useGlobalState(globalIsEditing)

    const getUserId = () => {
        return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
        typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
      }

    const [isEditing, setIsEditing] = useState(false)
    const [value, setValue] = useState(opcoes)

    const handleEditing = () => {
        setIsEditing(true)
        gIsEditing.set(() => true)
    }
    const handleBlur = () => {
        setIsEditing(false)
        gIsEditing.set(() => false)
    }
    const inputRef = useRef(null)

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus()
        }
        localStorage.setItem('balance',gBalance.get())

    },[isEditing])

    const handleFocus = (event) => event.target.select();

    const handleKeyDown = (event, type) => {
        const { key } = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey]; // All keys array

        if (
          (type === "textarea" && keys.indexOf(key) > -1) ||
          (type !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
           setIsEditing(false);
           gIsEditing.set(() => false)
        }
    }

    if (isEditing) {
        return (
                <>
                {
                    price ?
                    <div className={`cardConfig ${className}`} key={idx}>
                        <span>{descricao}</span>
                        <input className="cardConfig__input cardConfig__inputStyles"
                                value={value}
                                // value={Math.round((gBalance.get() + Number.EPSILON) * 100) / 100} 
                                type='number' 
                                ref={inputRef} 
                                onBlur={handleBlur} 
                                // onFocus={handleFocus}
                                onFocus={gBalance.get() === 0 | gBalance.get() === 10000? handleFocus : null}
                                // onChange={e => setValue(e.target.value)}
                                onChange={e => {
                                    updateBalance(getUserId(), parseFloat(e.target.value))
                                    gBalance.set(() => parseFloat(e.target.value))
                                    setValue(parseFloat(e.target.value))
        
                                    console.log("e.target.value",e.target.value)
                                    gInitialBalance.set(() => gBalance.get())
                                    localStorage.setItem('initialBalance',gInitialBalance.get())
        
                                    gEditedBalance.set(() => gBalance.get())
                                    localStorage.setItem('editedBalance',gEditedBalance.get())
                                    updateDataSlice(getUserId(), { editedBalance: gEditedBalance.get() })
        
                                    gReset.set(() => false)
                                    localStorage.setItem('reset',gReset.get())
                                }} 
                                onKeyDown={e => handleKeyDown(e, 'number')} 
                        />
                    </div>
                :
                <div className={`cardConfig ${className}`} key={idx} >
                    {disabled ?
                    <span className="cardConfig__disabled">{descricao}</span>
                    :
                    <span>{descricao}</span>
                    }
                    <span >{opcoes}</span>
                </div>
                }
                </>
            )
        }
    return (
        <>
                {
                    price ?
                    <div className={`cardConfig ${className}`} key={idx} >
                        <span>{descricao}</span>
                        <span className="cardConfig__editSpan" onClick={handleEditing}>{formatter.format(value)}<EditIcon/></span>
                    </div>
                :

                <div className={`cardConfig ${className}`} key={idx} >
                    {disabled ?
                    <span className="cardConfig__disabled">{descricao}</span>
                    :
                    <span>{descricao}</span>
                    }
                    <span>{opcoes}</span>
                </div>
                }
                </>
    )
    
}

export default CardConfig
