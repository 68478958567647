import "./HomeScreen.css";
import Nav from "./Nav";
import Card from "./Card";
import Plan0Option0 from "./Plan0Option0";
import Plan0Option1 from "./Plan0Option1";
import Plan0Option2 from "./Plan0Option2";
import Plan0Option3 from "./Plan0Option3";
import Plan1Option0 from "./Plan1Option0";
import Plan1Option1 from "./Plan1Option1";
import Plan1Option2 from "./Plan1Option2";
import Plan1Option3 from "./Plan1Option3";
// import Days from "./Days"
import BarChart from "./BarChart";
import SelectPlan from "./SelectPlan";
import DoughnutChart from "./DoughnutChart";
import History from "./History";
import globalBalance, {
  globalGanhei,
  globalPerdi,
  globalCiclos,
  globalRetornoPorCiclo,
  globalHojeTaxa,
  globalHistory,
  globalEditedBalance,
  globalOption,
  globalPlan,
  globalHoje,
  globalSemana,
  globalMes,
  globalAno,
  globalDays,
  globalUser,
  globalProxValor,
  globalAcertos,
  globalConnected,
  globalSubscription,
} from "./Global";
import { useState as useGlobalState } from "@hookstate/core";
import React, { useState, useEffect, useRef } from "react";
import Basic from "./Basic";
import { Persistence } from "@hookstate/persistence";
import { readHojeTaxa, updateDataSlice } from "./firebaseCRUD";
import firebase from "firebase";
import resetData from "./resetData";
import Modal from "@material-ui/core/Modal";
import TelaLoginRobo from "./TelaLoginRobo";
import TelaLogoutRobo from "./TelaLogoutRobo";
import FileDownload from "@material-ui/icons/GetApp";
import Info from "./Info";
import { Link } from "react-router-dom";

function HomeScreen() {
  const gSubscription = useGlobalState(globalSubscription);

  const [openLogin, setOpenLogin] = useState(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);
  const ref = useRef();

  const [custom, setCustom] = useState();
  const gUser = useGlobalState(globalUser);

  const getUserId = () => {
    return typeof gUser.get() === "string"
      ? JSON.parse(gUser.get())?.user?.uid
      : typeof gUser.get() === "object"
      ? gUser.get()?.user?.uid
      : "";
  };
  const userId = getUserId();
  const gGanhei = useGlobalState(globalGanhei);
  const gPerdi = useGlobalState(globalPerdi);
  const gBalance = useGlobalState(globalBalance);
  const gCiclos = useGlobalState(globalCiclos);
  const gRetornoPorCiclo = useGlobalState(globalRetornoPorCiclo);
  const gEditedBalance = useGlobalState(globalEditedBalance);
  const gHojeTaxa = useGlobalState(globalHojeTaxa);
  const gHistory = useGlobalState(globalHistory);

  const gHoje = useGlobalState(globalHoje);
  const gSemana = useGlobalState(globalSemana);
  const gMes = useGlobalState(globalMes);
  const gAno = useGlobalState(globalAno);
  const gConnected = useGlobalState(globalConnected);

  const gDays = useGlobalState(globalDays);

  const gPlan = useGlobalState(globalPlan);
  const gOption = useGlobalState(globalOption);
  const [firstTime, setFirstTime] = useState(true);
  const customersRef = firebase.firestore().collection("/customers");
  const userDocRef = customersRef.doc(userId);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const jurosCompostos = (valorInicial, taxa, vezes, periodo) => {
    let valorFinal = valorInicial * (1 + taxa) ** (vezes * periodo);
    let taxaFinal = (1 + taxa) ** (vezes * periodo);
    let tFinal = taxaFinal - 1;

    return {
      valor: valorFinal,
      taxa:
        tFinal >= gEditedBalance.get() * -1
          ? tFinal
          : gEditedBalance.get() * -1,
    };
  };

  const jurosCompostosHoje = (valorInicial, taxa, vezes, periodo) => {
    let valorFinal = gBalance.get();
    let hTaxa = (valorFinal - valorInicial) / valorInicial;
    console.log("valorFinal", valorFinal);
    console.log("valorInicial", valorInicial);
    console.log("valorFinal-valorInicial", valorFinal - valorInicial);
    console.log("hTaxa", hTaxa);
    return {
      valor: valorFinal,
      taxa: hTaxa >= -1 ? hTaxa : -1,
    };
  };

  useEffect(() => {
    console.log("gEditedBalance.get()", gEditedBalance.get());
    gHoje.set(() =>
      jurosCompostosHoje(
        gEditedBalance.get(),
        gRetornoPorCiclo.get(),
        gCiclos.get(),
        1
      )
    );
    gHoje.attach(Persistence("hoje"));
    localStorage.setItem("history", JSON.stringify(gHistory.get()));
    gSemana.set(() =>
      jurosCompostos(gEditedBalance.get(), gHoje.get().taxa, 1, 7)
    );
    gMes.set(() =>
      jurosCompostos(gEditedBalance.get(), gHoje.get().taxa, 1, 30)
    );
    gAno.set(() =>
      jurosCompostos(gEditedBalance.get(), gHoje.get().taxa, 1, 365)
    );
    const hojeTaxaBackup = gHojeTaxa.get();
    console.log("gHojeTaxa BACKUP ===============>", hojeTaxaBackup);
    gHojeTaxa.set(() => gHoje.get().taxa);
    if (gHojeTaxa.get() === 1) {
      gHojeTaxa.set(() => hojeTaxaBackup);
      localStorage.setItem("hojeTaxa", gHojeTaxa.get());
    } else {
      localStorage.setItem("hojeTaxa", parseFloat(gHoje.get().taxa));
    }
    localStorage.setItem(
      "hojeTaxa",
      parseFloat(gHoje.get().taxa) !== 1
        ? parseFloat(gHoje.get().taxa)
        : readHojeTaxa(userId)
    );

    updateDataSlice(userId, { hojeTaxa: gHoje.get().taxa }); //, balance: gEditedBalance.get() })
  }, [gBalance.get()]);

  useEffect(() => {
    const customersRef = firebase.firestore().collection("/customers");
    const userDocRef = customersRef.doc(userId);

    userDocRef.get().then((userDoc) => {
      if (userDoc?.exists && Object.keys(userDoc?.data()).length < 12) {
        resetData(userId);
      }
    });
  }, []);

  useEffect(() => {
    if ((gGanhei.get() > 0) | (gPerdi.get() > 0)) {
      if (!globalProxValor.get()) {
        const reloadCount = sessionStorage.getItem("reloadCount");
        if (reloadCount < 2) {
          sessionStorage.setItem("reloadCount", String(reloadCount + 1));
          window.location.reload();
        } else {
          sessionStorage.removeItem("reloadCount");
        }
      }
    }
    console.log("gGanhei", gGanhei.get());
    console.log("gPerdi.get()", gPerdi.get());
  }, [gGanhei.get(), gPerdi.get()]);

  // useEffect(() => {

  // // read first time
  // userDocRef.get().then((userDoc) => {
  //     if (userDoc.exists && userDoc.data().length > 8){
  //         if (!globalProxValor.get()) {
  //             window.location.reload();
  //         }
  //     }

  // }).catch((error) => {
  //     console.log("Error getting user Homescreen:", error);
  // })

  // },[gGanhei.get(), gPerdi.get()])

  useEffect(() => {
    gHoje.merge({ taxa: gHojeTaxa.get() });
    gSemana.set(() => jurosCompostos(gBalance.get(), gHoje.get().taxa, 1, 7));
    gMes.set(() => jurosCompostos(gBalance.get(), gHoje.get().taxa, 1, 30));
    gAno.set(() => jurosCompostos(gBalance.get(), gHoje.get().taxa, 1, 365));
    setCustom(
      jurosCompostos(gEditedBalance.get(), gHoje.get().taxa, 1, gDays.get())
    );
  }, [gHojeTaxa.get()]);

  useEffect(() => {
    gPlan.get() === 0 && gOption.get() === 0
      ? gRetornoPorCiclo.set(() => 0.0064)
      : gPlan.get() === 0 && gOption.get() === 1
      ? gRetornoPorCiclo.set(() => 0.009)
      : gPlan.get() === 0 && gOption.get() === 2
      ? gRetornoPorCiclo.set(() => 0.0357)
      : gPlan.get() === 0 && gOption.get() === 3
      ? gRetornoPorCiclo.set(() => 0.1045)
      : gPlan.get() === 1 && gOption.get() === 0
      ? gRetornoPorCiclo.set(() => 0.0015)
      : gPlan.get() === 1 && gOption.get() === 1
      ? gRetornoPorCiclo.set(() => 0.0069)
      : gPlan.get() === 1 && gOption.get() === 2
      ? gRetornoPorCiclo.set(() => 0.0375)
      : gPlan.get() === 1 && gOption.get() === 3
      ? gRetornoPorCiclo.set(() => 0.022) // change it here
      : gRetornoPorCiclo.set(() => 0);

    updateDataSlice(userId, {
      plan: gPlan.get() ? gPlan.get() : 0,
      option: gOption.get() ? gOption.get() : 0,
    });
  }, [gPlan.get(), gOption.get()]);

  useEffect(() => {
    updateDataSlice(userId, { customDays: gDays.get() });
    console.log("gEditedBalance.get()", gEditedBalance.get());
  }, [gDays.get(), gEditedBalance.get()]);

  const handleOpenZoom = () => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href =
      "https://us04web.zoom.us/j/7279217667?pwd=EoNmdm7xwkbxUPG-0nA9ZkoYezHDeA.1";
    a.click();
  };

  return (
    <>
      <Nav />

      <div className="homeScreen">
        <div className="homeScreen__selectPlanRoboContainer">
          <div className="homeScreen__selectPlan--Mobile">
            <SelectPlan />
          </div>
          {gSubscription.get() === "Automatizado" && (
            <div className="homeScreen__roboContainer">
              <div className="homeScreen__connect" onClick={handleOpenLogin}>
                <span
                  className={`homeScreen__connectCircle ${
                    gConnected.get() ? "homeScreen__connectCircleActive" : ""
                  }`}
                />
                {gConnected.get() ? "Robô Conectado" : "Robô Desconectado"}
              </div>

              <Modal
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                open={openLogin}
                onClose={handleCloseLogin}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                {gConnected.get() ? (
                  <TelaLogoutRobo
                    ref={ref}
                    handleCloseLogin={handleCloseLogin}
                  />
                ) : (
                  <TelaLoginRobo
                    ref={ref}
                    handleCloseLogin={handleCloseLogin}
                  />
                )}
              </Modal>
            </div>
          )}

          {(gSubscription.get() === "Automatizado" ||
            gSubscription.get() === "Profissional") && (
            <div
              className="homeScreen__butttonZoomMobile"
              onClick={handleOpenZoom}
            >
              <span>Sala Zoom</span>
              <img className="homeScreen__butttonZoom_img" src="zoomIcon.png" />
            </div>
          )}

          {!gConnected.get() && gSubscription.get() === "Automatizado" && (
            <a href="robo-18-jan-22.exe" download>
              <Info description="Faça o download do Robô">
                <div className="mainCard__topRightButtonDownloadMobile">
                  <FileDownload style={{ fontSize: 22 }} />
                  <span>Download</span>
                </div>
              </Info>
            </a>
          )}
        </div>

        <Basic />
        <div className="homeScreen__outerContainer">
          <div className="homeScreen__HistoryDesktop">
            <History title="Histórico de Trades" trades={gHistory.get()} />
          </div>

          <div className="homeScreen__outerRight">
            <div className="homeScreenMaincardMobile">
              {gPlan.get() === 0 && gOption.get() === 0 ? (
                <Plan0Option0 connected={gConnected.get()} />
              ) : gPlan.get() === 0 && gOption.get() === 1 ? (
                <Plan0Option1 connected={gConnected.get()} />
              ) : gPlan.get() === 0 && gOption.get() === 2 ? (
                <Plan0Option2 connected={gConnected.get()} />
              ) : gPlan.get() === 0 && gOption.get() === 3 ? (
                <Plan0Option3 connected={gConnected.get()} />
              ) : gPlan.get() === 1 && gOption.get() === 0 ? (
                <Plan1Option0 connected={gConnected.get()} />
              ) : gPlan.get() === 1 && gOption.get() === 1 ? (
                <Plan1Option1 connected={gConnected.get()} />
              ) : gPlan.get() === 1 && gOption.get() === 2 ? (
                <Plan1Option2 connected={gConnected.get()} />
              ) : gPlan.get() === 1 && gOption.get() === 3 ? (
                <Plan1Option3 connected={gConnected.get()} />
              ) : (
                <></>
              )}
            </div>

            <div className="homeScreen__container">
              <div className="homeScreen__containerTop">
                <div className="homeScreen__cardsContainer">
                  <div className="homeScreen__cardsContainerTop">
                    <Card
                      title="Saldo"
                      balance={formatter.format(
                        gBalance.get() ? gBalance.get() : 0
                      )}
                      frequency="Diário"
                      compound={gHoje.get().taxa ? gHoje.get().taxa : 0}
                      description="Retorno de hoje"
                    />

                    <Card
                      title="Saldo"
                      balance={formatter.format(
                        gSemana.get().valor ? gSemana.get().valor : 0
                      )}
                      frequency="Semanal"
                      compound={gSemana.get().taxa ? gSemana.get().taxa : 0}
                      description="Daqui a uma semana"
                    />
                  </div>
                  <div className="homeScreen__cardsContainerBottom">
                    <Card
                      title="Saldo"
                      balance={formatter.format(
                        gMes.get().valor ? gMes.get().valor : 0
                      )}
                      frequency="Mensal"
                      compound={gMes.get().taxa ? gMes.get().taxa : 0}
                      description="Daqui a um mês"
                    />

                    <Card
                      title="Saldo"
                      balance={formatter.format(
                        custom?.valor ? custom?.valor : 0
                      )}
                      frequency="Customizado"
                      compound={custom?.taxa ? custom?.taxa : 0}
                      description={`Daqui a ${gDays.get()} dias`}
                      isCustom
                    />
                  </div>
                </div>

                <div className="homeScreenCharts">
                  <div className="homeScreenMaincardDesktop">
                    {gPlan.get() === 0 && gOption.get() === 0 ? (
                      <Plan0Option0
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 0 && gOption.get() === 1 ? (
                      <Plan0Option1
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 0 && gOption.get() === 2 ? (
                      <Plan0Option2
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 0 && gOption.get() === 3 ? (
                      <Plan0Option3
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 1 && gOption.get() === 0 ? (
                      <Plan1Option0
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 1 && gOption.get() === 1 ? (
                      <Plan1Option1
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 1 && gOption.get() === 2 ? (
                      <Plan1Option2
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : gPlan.get() === 1 && gOption.get() === 3 ? (
                      <Plan1Option3
                        desktopDisplay
                        connected={gConnected.get()}
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="homeScree__lineBar">
                    <DoughnutChart
                      title="Acertos x Erros"
                      wins={parseFloat(gGanhei.get())}
                      losses={parseFloat(gPerdi.get())}
                    />

                    <BarChart title="Projeção Anual (%)" />

                    {/* <LineChart 
                                        title="Projeção Anual (%)"
                                        />   */}
                  </div>

                  <div className="homeScreen__HistoryMobile">
                    <History
                      title="Histórico de Trades"
                      trades={gHistory.get()}
                    />
                  </div>
                </div>
              </div>

              <div className="homeScreenMaincardDesktop">
                {gPlan.get() === 0 && gOption.get() === 0 ? (
                  <Plan0Option0 connected={gConnected.get()} />
                ) : gPlan.get() === 0 && gOption.get() === 1 ? (
                  <Plan0Option1 connected={gConnected.get()} />
                ) : gPlan.get() === 0 && gOption.get() === 2 ? (
                  <Plan0Option2 connected={gConnected.get()} />
                ) : gPlan.get() === 0 && gOption.get() === 3 ? (
                  <Plan0Option3 connected={gConnected.get()} />
                ) : gPlan.get() === 1 && gOption.get() === 0 ? (
                  <Plan1Option0 connected={gConnected.get()} />
                ) : gPlan.get() === 1 && gOption.get() === 1 ? (
                  <Plan1Option1 connected={gConnected.get()} />
                ) : gPlan.get() === 1 && gOption.get() === 2 ? (
                  <Plan1Option2 connected={gConnected.get()} />
                ) : gPlan.get() === 1 && gOption.get() === 3 ? (
                  <Plan1Option3 connected={gConnected.get()} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(HomeScreen);
