import './Button.css'

function Button({ className, label, children, onClick }) {
    return (
        <div onClick={onClick} className={`button ${className}`}>
            {label ? label : children}
        </div>
    )
}

export default Button
