import './MeuPlano.css'
import { globalSubscription, globalUser } from './Global'
import { useState as useGlobalState } from '@hookstate/core'
import { Avatar, makeStyles } from '@material-ui/core'
import Nav from './Nav'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import db, { app, auth } from './firebase'
import { loadStripe } from '@stripe/stripe-js'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Loading from './Loading'
import { logout } from "./features/userSlice"
import logUserOut from './logUserOut' 
import { useDispatch } from 'react-redux';

function MeuPlano() {
    const gUser = useGlobalState(globalUser)
    const gSubscription = useGlobalState(globalSubscription)
    const history = useHistory()
    const dispatch = useDispatch()

    const [subscription, setSubscription] = useState(null)
    const [products, setProducts] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    
    const getUserId = () => {
        return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
        typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
        
    }

    const goToDashboard = () => {
        history.push('/dashboard')
    }   

    // useEffect(() => {
    //     auth.currentUser.delete()
    //     .then(() => {
    //         console.log('user deleted successfully!')
    //     })
    //     .catch(e => console.log(e))
    // },[])

    useEffect(() => {
        db.collection('customers')
        .doc(getUserId())
        .collection('subscriptions')
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(async subscription => {     
                setSubscription({
                    role: subscription.data().role,
                    current_period_end: subscription.data().current_period_end.seconds,
                    current_period_start: subscription.data().current_period_start.seconds,
                    cancel_at_period_end: subscription.data().cancel_at_period_end
                })
                localStorage.setItem('subscription',subscription.data()?.role) 
                gSubscription.set(() => subscription.data()?.role)
                
                //Only allow active users to log in
                if (subscription.data()?.status !== 'active') {
                    setSubscription({})
                    localStorage.setItem('subscription', null) 
                    gSubscription.set(() => null)
                }

                
            })
        })
    },[])

    useEffect(() => {
        db.collection('products')
        .where('active', '==', true)
        .get().then(querySnapshot => {
            const products = {}
            querySnapshot.forEach(async productDoc => {
                products[productDoc.id] = productDoc.data()
                const priceSnap = await productDoc.ref.collection('prices').get()
                priceSnap.docs.forEach(priceDoc => {
                    products[productDoc.id].prices = {
                        priceId: priceDoc.id,
                        priceData: priceDoc.data()
                    }
                })
            })
            setProducts(products)
        })
    },[])
    

    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          '& > *': {
            margin: theme.spacing(1),
          },
        },
        small: {
          width: theme.spacing(3),
          height: theme.spacing(3),
        },  
        large: {
          width: theme.spacing(7),
          height: theme.spacing(7),
        },
      }));

    const classes = useStyles();  

    const handleLogout = () => {
        console.log("Calling logUserOut!!!!")  
        logUserOut(dispatch, gSubscription, gUser,history)
        logout()
         
        
    }

    const loadCheckout = async (priceId) => {
        setIsLoading(true);
        const docRef = await db.collection('customers')
        .doc(getUserId())
        .collection("checkout_sessions")
        .add({
            price: priceId,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin
        })
        // const docRef = await db
        // .collection("customers")
        // .doc(getUserId())
        // .collection("checkout_sessions")
        // .add({
        //   line_items: [
        //     {
        //       price: priceId,
        //       quantity: 1,
        //     },
        //   ],
        //   success_url: window.location.origin,
        //   cancel_url: window.location.origin,
        // });


        docRef.onSnapshot(async(snap) => {
            const { error, sessionId } = snap.data()
            if (error) {
                // alert(`Ocorreu um erro: ${error.message}`)
                alert(`Ocorreu um erro ao tentar se conectar com o Stripe. Por favor, tente mais tarde.`)
                console.log('erro do stripe',error.message)
            }

            if (sessionId) {
                const stripe = await loadStripe('pk_live_51JOqA3HG3aKHOYzv0u0vZ1vB4rmt4wDv4FL7HZjd7yE3YRxfNpPxR4ebNbjpOlLHsdMzypbllD228YZRLlRbxq2x00cA2pWfNC')
                stripe.redirectToCheckout({ sessionId })
            }
        })
    }

    const goToBillingPortal = async () => {
        // Call billing portal function
        setIsLoading(true);
        const functionRef = app
            .functions('us-central1')
            .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
            const { data } = await functionRef({ returnUrl: window.location.origin });
            window.location.assign(data.url);
        
        window.location.assign(data.url);
      };
    
    return (
        <>
        <Nav myPlan />
        <div className="meuPlano">
            <div className="meuPlano__container">
                <div className="meuPlano__header">
                    <h1>Meu Plano</h1>
                </div>
                <div className="meuPlano__body">
                    <div className="meuPlano__right">
                        <div className="meuPlano__rightProfile">
                            <div className="meuPlano__rightAvatarName">
                                <Avatar className={classes.largbe} src={typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.photoURL : 
                                                            typeof gUser.get() === 'object' ? gUser.get()?.user?.photoURL : ""} />

                                <div className="meuPlano__rightNameEmail">
                                    <h1>{typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.displayName : 
                                                                typeof gUser.get() === 'object' ? gUser.get()?.user?.displayName : "Não Registrado"}</h1>
                                    <p>{typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.email : 
                                                                typeof gUser.get() === 'object' ? gUser.get()?.user?.email : "-"}</p>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className="meuPlano__rightHeader">
                            <h1>Plano Atual:</h1><strong>{subscription?.role ? subscription?.role : "Nenhum"}</strong>
                        </div>
                        <div className="meuPlano__rightBody">

                            <div className="meuPlano__rightBodyRenewal">
                                {!subscription?.cancel_at_period_end ? 
                                        <p>Data de Renovação:</p>
                                    :
                                    <p>Perderá acesso dia:</p>}<strong>{subscription?.current_period_end ? new Date(subscription?.current_period_end * 1000).toLocaleDateString() : '---'}</strong>
                            </div>
                            {!isLoading ?
                                <div className="meuPlano__rightBodyPlans">                               
                                    {Object.entries(products).map(([productId, productData]) => {
                                        // check subscription 
                                        const isCurrentPackage = productData.name?.includes(subscription?.role)
                                        return (
                                            <div key={productId} className="meuPlano__rightBodyPlansPlan">
                                            <div className="meuPlano__planLeft">
                                                <h1>{productData.name}</h1>
                                                <p>{productData.description}</p>
                                            </div>
                                            <div className="meuPlano__planRight">
                                            <div 
                                                className={isCurrentPackage ? 
                                                `meuPlano__planRightButton meuPlano__planRightCurrent` 
                                                : `meuPlano__planRightButton`} 

                                                onClick={() => 
                                                isCurrentPackage ? 
                                                null
                                                : 
                                                !isCurrentPackage && !subscription ? 
                                                    loadCheckout(productData.prices?.priceId)
                                                :
                                                goToBillingPortal()}
                                            >
                                                    {isCurrentPackage ? 
                                                        'Plano Atual' 
                                                        : 
                                                    !subscription && !isCurrentPackage ? 
                                                        `${!isLoading ? "Assinar" : "Aguarde..."}`
                                                        :
                                                        'Escolher Esse'
                                                    }
                                                    {isLoading && !isCurrentPackage && <Loading />}
                                                    
                                                    
                                                </div>
                                                {isCurrentPackage && !subscription?.cancel_at_period_end ?
                                                <div className="meuPlano__cancel" onClick={() => goToBillingPortal()}>
                                                    Cancelar
                                                </div>
                                                :
                                                isCurrentPackage ?
                                                <div className="meuPlano__cancel" onClick={() => goToBillingPortal()}>Renovar</div>
                                                :
                                                <></>}                                               
                                            </div>
                                  
                                            </div>
                                        )
                                    })}
                                
                            </div> 
                            :
                            <div className="meuPlano__loading">
                            <Loading size={75}/>  
                            <span>Carregando...</span>
                            </div>  
                            }
                            <div className="meuPlano__planRightSignOut" onClick={handleLogout}>
                                Sair da Conta
                            </div>
                            {subscription &&
                            <div className="meuPlano__planRightBack" onClick={goToDashboard}>
                                <ArrowBackIcon />
                                <p>{"Voltar ao Software"}</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MeuPlano
