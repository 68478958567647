import { auth } from './firebase'
import { logout } from "./features/userSlice"

const logUserOut = (dispatch, gSubscription, gUser,history) => {
    dispatch(logout)
    auth.signOut().then(function() {
    console.log("Sign-out successful.")
    }).catch(function(error) {
        console.log("An error happened:",error)
    });
    
    gUser.set(() => null)
    localStorage.removeItem('user')
    
    gSubscription.set(() => null)
    localStorage.removeItem('subscription')
    history.push('/')
    localStorage.clear()
}

export default logUserOut
