import './SimpleButton.css';

function SimpleButton({nome, className, variant, onClick}) {
    return (
        <>
        {variant !== "laranja" ? 
        
        <div className={`simpleButton ${className}`} onClick={onClick}>
           <span>{nome}</span>
        </div> 
        :
        <div className={`simpleButton simpleButton__salvar ${className}`} onClick={onClick}>
           <span>{nome}</span>
        </div>
        }
        </>

    )
}

export default SimpleButton
