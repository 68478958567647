import "./Nav.css";
import { Avatar, makeStyles } from "@material-ui/core";
import EditInPlace from "./EditInPlace";
import SelectPlan from "./SelectPlan";
import { useState as useGlobalState } from "@hookstate/core";
import globalBalance, {
  globalGanhei,
  globalPerdi,
  globalAcertos,
  globalCiclos,
  globalOportunidades,
  globalProxValor,
  globalLucroRestante,
  globalRetornoPorCiclo,
  globalReset,
  globalHistory,
  globalUser,
  globalSubscription,
  globalConnected,
  globalPayout,
  globalPlay,
  globalEditedBalance,
} from "./Global";

import Logout from "./Logout";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { updateBalance, updateDataSlice } from "./firebaseCRUD";
import resetData from "./resetData";
import Modal from "@material-ui/core/Modal";
import TelaLoginRobo from "./TelaLoginRobo";
import TelaLogoutRobo from "./TelaLogoutRobo";
import db, { auth } from "./firebase";
import Button from "./Button";
import SettingsIcon from "@material-ui/icons/Settings";

function Nav({ myPlan }) {
  let robo_balance = null;
  const gUser = useGlobalState(globalUser);
  const gGanhei = useGlobalState(globalGanhei);
  const gPerdi = useGlobalState(globalPerdi);
  const gBalance = useGlobalState(globalBalance);
  const gEditedBalance = useGlobalState(globalEditedBalance);
  const gAcertos = useGlobalState(globalAcertos);
  const gCiclos = useGlobalState(globalCiclos);
  const gOportunidades = useGlobalState(globalOportunidades);
  const gProxValor = useGlobalState(globalProxValor);
  const gLucroRestante = useGlobalState(globalLucroRestante);
  const gRetornoPorCiclo = useGlobalState(globalRetornoPorCiclo);
  const gReset = useGlobalState(globalReset);
  const gHistory = useGlobalState(globalHistory);
  const history = useHistory();
  const gSubscription = useGlobalState(globalSubscription);
  const [subscription, setSubscription] = useState(null);

  // function deleteAllCookies() {
  //   var cookies = document.cookie.split(";");

  //   for (var i = 0; i < cookies.length; i++) {
  //       var cookie = cookies[i];
  //       var eqPos = cookie.indexOf("=");
  //       var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //       document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  //   }
  // }

  useEffect(() => {
    db.collection("customers")
      .doc(getUserId())
      .collection("subscriptions")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (subscription) => {
          setSubscription({
            role: subscription.data().role,
            current_period_end: subscription.data().current_period_end.seconds,
            current_period_start:
              subscription.data().current_period_start.seconds,
            cancel_at_period_end: subscription.data().cancel_at_period_end,
          });
          localStorage.setItem("subscription", subscription.data()?.role);
          gSubscription.set(() => subscription.data()?.role);

          localStorage.setItem("status", subscription.data()?.status);

          //Only allow active users to log in
          if (subscription.data()?.status !== "active") {
            setSubscription({});
            localStorage.setItem("subscription", null);
            gSubscription.set(() => null);
            history.push("/meu-plano");
          }
        });
      });
  }, [gBalance.get()]);

  useEffect(() => {
    if (!gSubscription.get()) {
      history.push("/meu-plano");
    }
  }, [gBalance.get()]);

  // useEffect(() => {
  //   //remove cookies
  //   deleteAllCookies()
  // },[])

  const [openLogin, setOpenLogin] = useState(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);
  const gConnected = useGlobalState(globalConnected);
  const gPlay = useGlobalState(globalPlay);

  const ref = useRef();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));

  const classes = useStyles();

  const getUserId = () => {
    return typeof gUser.get() === "string"
      ? JSON.parse(gUser.get())?.user?.uid
      : typeof gUser.get() === "object"
      ? gUser.get()?.user?.uid
      : "";
  };

  const handleReset = () => {
    updateBalance(getUserId(), 0);
    resetData(getUserId());

    gReset.set(() => true);
    localStorage.setItem("reset", gReset.get());
  };

  const handleClick = () => {
    history.push("/dashboard");
  };

  const handleOpenZoom = () => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href =
      "https://us04web.zoom.us/j/7279217667?pwd=EoNmdm7xwkbxUPG-0nA9ZkoYezHDeA.1";
    a.click();
  };

  const handleRobo = () => {
    history.push("/robo");
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (getUserId()) {
      db.collection("customers")
        .doc(getUserId())
        .onSnapshot((snapshot) => {
          if (snapshot.data()?.robo_desconectou === true) {
            gConnected.set(() => false);
          }
          updateDataSlice(getUserId(), { robo_desconectou: false });
        });
    }
  }, [getUserId()]);

  useEffect(() => {
    if (gPlay.get() === false) {
      gConnected.set(() => false);
    }
  }, [gPlay.get()]);

  return (
    <div className="nav">
      {/* <img className="nav__logo"
            src="https://img.imageboss.me/orbitpages/height/80/withoutEnlargement:true/sites/10478/2021/05/logologo-horizontal.png" 
            alt="" /> */}

      {/* <div className="nav__text" onClick={handleClick}>
              <h1 className="nav__textO">O</h1>
              <h1>GERENCIAMENTO</h1>
            </div> */}
      <img src="OGERENCIAMENTO_BRANCO.png" onClick={handleClick} />
      {myPlan ? (
        <></>
      ) : (
        <>
          <div className="nav__selectPlan--Desktop">
            <SelectPlan />
          </div>

          {gSubscription.get() === "Automatizado" && (
            <div className="nav__roboContainer">
              <div className="homeScreen__connect" onClick={handleOpenLogin}>
                <span
                  className={`homeScreen__connectCircle ${
                    gConnected.get() ? "homeScreen__connectCircleActive" : ""
                  }`}
                />
                {gConnected.get() ? "Robô Conectado" : "Robô Desconectado"}
              </div>
              <Modal
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                open={openLogin}
                onClose={handleCloseLogin}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                {gConnected.get() ? (
                  <TelaLogoutRobo
                    ref={ref}
                    handleCloseLogin={handleCloseLogin}
                  />
                ) : (
                  <TelaLoginRobo
                    ref={ref}
                    handleCloseLogin={handleCloseLogin}
                  />
                )}
              </Modal>
            </div>
          )}

          <div className="nav__reset" onClick={handleReset}>
            Reset
          </div>

          {(gSubscription.get() === "Automatizado" ||
            gSubscription.get() === "Profissional") && (
            <div className="homeScreen__butttonZoom" onClick={handleOpenZoom}>
              <span>Sala Zoom</span>
              <img className="homeScreen__butttonZoom_img" src="zoomIcon.png" />
            </div>
          )}

          <div className="nav__right">
            <Logout>
              <div className="avatar_container">
                <Avatar
                  className={classes.small}
                  src={
                    typeof gUser.get() === "string"
                      ? JSON.parse(gUser.get())?.user?.photoURL
                      : typeof gUser.get() === "object"
                      ? gUser.get()?.user?.photoURL
                      : ""
                  }
                />
              </div>
            </Logout>
            <EditInPlace />
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Nav);
