import Button from "./Button"
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import './MainCard.css'
import {
        globalAcertos, 
        globalCiclos, 
        globalOportunidades, 
        globalProxValor,
        globalRetornoPorCiclo,
        globalLucroRestante } from './Global11'
                        
import globalBalance, { globalGanhei, 
                        globalPerdi, 
                        globalPayout,
                        globalHistory,
                        globalInitialBalance,
                        globalUser,
                        globalMinimum,
                        globalWins,
                        globalLosses,
                        globalPlay,
                        globalRoboGanhou, //robo
                        globalRoboPerdeu,
                        globalSubscription,
                        globalPlan,
                        globalOption,  //robo                  
                      } from './Global'

import { useState as useGlobalState } from '@hookstate/core'
import { useEffect, useMemo } from 'react'
import MainDesktop from "./MainDesktop";
import { readPlan, updateDataSlice } from './firebaseCRUD'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SettingsIcon from '@material-ui/icons/Settings';
import StopIcon from '@material-ui/icons/Stop';
import { useState } from 'react'
import TelaConfigRobo from "./TelaConfigRobo"
import Modal from '@material-ui/core/Modal';
import CryptoJS from 'crypto-js'
import FileDownload from '@material-ui/icons/GetApp'
import Info from './Info'
import db from './firebase'
import MuiAlert from '@material-ui/lab/Alert'; //Alert

let first_time_perdi = true //robo
let first_time_ganhei = true //robo
function MainCard({ desktopDisplay, connected }) {
    // BEGIN: Alert
    const [error, setError] = useState(null)
    const [stopPlay, setStopPlay] = useState(false)
    function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false)
        handleToggle()
        setError(null)
    }
    // END: Alert
    const gSubscription = useGlobalState(globalSubscription)

    const gRoboPerdeu = useGlobalState(globalRoboPerdeu) //robo
    const gRoboGanhou = useGlobalState(globalRoboGanhou) //robo 

    const gPlay = useGlobalState(globalPlay)
    const [openConfig, setOpenConfig] = useState(false);
    const handleOpenConfig = () => setOpenConfig(true);
    const handleCloseConfig = () => setOpenConfig(false);
    const userId = JSON.parse(localStorage.getItem('user'))

    const gBalance = useGlobalState(globalBalance)
    

    const handleToggle = () => {
        gPlay.set(prev => {
            localStorage.setItem('play',!prev)
            return !prev
        })

        const ciphertext = localStorage.getItem('ciphertext')
        
        // Decrypt
        const bytes = CryptoJS.AES.decrypt(ciphertext, 'DontTellAnyoneText');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        
        let currentData = {}
        if (!gPlay.get()) {
            currentData = { email: decryptedData[0].email, 
                            password: decryptedData[0].password,
                            logout: !gPlay.get() 
                          }
        }else {
            currentData = { email: decryptedData[0].email, 
                            password: decryptedData[0].password, 
                            stopLoss: localStorage.getItem('stopLoss'),
                            stopWin: localStorage.getItem('stopWin'),
                            balance: gBalance.get(), 
                         }
        }
        
        //
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(currentData)
        };
        fetch(`http://localhost:5000/connect/${userId.user.uid}`, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
    
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                if (!data.success) gPlay.set(() => false)
            })
            .catch(error => {
                console.log('an error has occurred while trying to start',error)
                gPlay.set(() => false)
            });
        //  
    }
    const getUserId = () => {
        return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
        typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
      }

    const getDateTime = () => {
        const today = new Date();
        const dateString = today.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        let currentDate = dateString.split('.')[0].replace('de ','')
        let currentTime = dateString.split('.')[1].slice(9,dateString.length)
        return {
            date: currentDate,
            time: currentTime
        }
    }

    
    // definir dinamicamente com o plano
    const oportunidades = 30
    const acertosPorCiclo = 3
    

    const gGanhei = useGlobalState(globalGanhei)
    const gPerdi = useGlobalState(globalPerdi)
    const gInitialBalance = useGlobalState(globalInitialBalance)
    const gAcertos = useGlobalState(globalAcertos)
    const gCiclos = useGlobalState(globalCiclos)
    const gOportunidades = useGlobalState(globalOportunidades)
    const gProxValor = useGlobalState(globalProxValor)
    const gPayout = useGlobalState(globalPayout)
    const gRetornoPorCiclo = useGlobalState(globalRetornoPorCiclo)
    const gLucroRestante = useGlobalState(globalLucroRestante)
    const gHistory = useGlobalState(globalHistory)
    const gWins = useGlobalState(globalWins)
    const gLosses = useGlobalState(globalLosses)
    const gUser = useGlobalState(globalUser)
    const gMinimum = useGlobalState(globalMinimum)
    const userIdMemoized = useMemo(() => getUserId(), [gUser.get()])
    // const tradesRef = db.collection('customers').doc(userIdMemoized).collection('trades')

    const losses = [0.1246, 0.147, 0.1736, 0.2049, 0.2419, 0.2856, 0.337, 0.398, 0.47, 0.555, 0.655, 0.773, 0.913, 1.077, 1.272, 1.502, 1.773, 2.093, 2.47, 2.916, 3.443, 4.064, 4.798, 5.235, 6.609, 7.803, 9.2112, 10.8741, 12.83723, 15.15474]
  
    const wins = []
    
    // BEGIN: SINCRONIZAÇÃO COM O ROBÔ
    useEffect(() => {
        if (userId.user.uid) {
            db.collection('customers').doc(userId.user.uid).onSnapshot(snapshot => {
                if (snapshot.data()?.last_perdeu !== snapshot.data()?.robo_perdeu && gPlay.get() !== false) {
                    gRoboPerdeu.set(() => {
                        first_time_perdi = false 
                        first_time_ganhei = false
                        return snapshot.data()?.robo_perdeu
                    })
                }
                if (snapshot.data()?.last_ganhou !== snapshot.data()?.robo_ganhou && gPlay.get() !== false) {
                    gRoboGanhou.set(() => {
                        first_time_perdi = false 
                        first_time_ganhei = false
                        return snapshot.data()?.robo_ganhou
                    })
                }
                db.collection('customers').doc(userId.user.uid).update({last_perdeu: snapshot.data()?.robo_perdeu, last_ganhou: snapshot.data()?.robo_ganhou})
            })
        }
    },[userId.user.uid])

    useEffect(() => {
        if (!first_time_perdi) {
            handlePerdi()
            first_time_perdi = true 
        }
    },[gRoboPerdeu.get()])

    useEffect(() => {
        if (!first_time_ganhei) {
            handleGanhei()
            first_time_ganhei = true
        }
    },[gRoboGanhou.get()])
    // END: SINCRONIZAÇÃO COM O ROBÔ
    
    const getValor = () => {
        let i = oportunidades-gOportunidades.get()

        if (gAcertos.get() === 0) {
            if (i <= losses.length) {
                let valor = (losses[i] * gInitialBalance.get())/100
                return valor
            }
            return 0
        }

        if (gAcertos.get() === 1 || gAcertos.get() === 2) {
            return gProxValor.get() + gProxValor.get()*gPayout.get()
        }

        
    }

    const getValorPerdi = () => {
        let i = oportunidades-gOportunidades.get()

        if (i <= losses.length) {
            let valor = (losses[i] * gInitialBalance.get())/100
            return valor
        }
        return 0        
    }

    const handleGanhei = () => {

        let dt = getDateTime()

        gHistory.set(p => [
            {   
                date: dt.date,
                time: dt.time,
                invested: gProxValor.get(),
                result: gProxValor.get() * gPayout.get()
            }, ...p
        ])        

        // tradesRef.add({   
        //     date: dt.date,
        //     time: dt.time,
        //     invested: gProxValor.get(),
        //     result: gProxValor.get() * gPayout.get(),
        //     timestamp: firebase.firestore.FieldValue.serverTimestamp()
        // })

        // tradesRef.orderBy('timestamp','desc')
        // .onSnapshot( tradeSnapshot => {
        //     gHistory.set(tradeSnapshot.docs.map(
        //        trade => trade?.data()
        //     ))
        // })

        gGanhei.set( p => p + 1 )
        localStorage.setItem('ganhei',gGanhei.get())
        
        gAcertos.set((p => p + 1))
        localStorage.setItem('acertos11',gAcertos.get())       

        let lucroRecebido = gProxValor.get() * gPayout.get()

        let lucroEsperado = gBalance.get() * gRetornoPorCiclo.get()
        
        if (lucroRecebido < lucroEsperado) {
            gLucroRestante.set(lucroEsperado - lucroRecebido)
            localStorage.setItem('lucroRestante11',gLucroRestante.get())

            
        } else {
            gLucroRestante.set(() => lucroEsperado)
            localStorage.setItem('lucroRestante11',gLucroRestante.get())
            
        }

        let valor = getValor()
        console.log("PRIMEIRO VALOR",valor)

        gProxValor.set(() => valor)
        localStorage.setItem('proxValor11',parseFloat(valor))
        
        gBalance.set(p => p +  lucroRecebido)

        localStorage.setItem('balance',gBalance.get())

        if (gAcertos.get() >= acertosPorCiclo) {
            gInitialBalance.set(() => gBalance.get())
            
            gAcertos.set(0)
            localStorage.setItem('acertos11',0)
            
            gCiclos.set(p => p + 1)
            localStorage.setItem('ciclos11',gCiclos.get())
            
            gOportunidades.set(oportunidades)
            localStorage.setItem('oportunidades11',gOportunidades.get())
            
            
            gLucroRestante.set(() => (gRetornoPorCiclo.get() * gBalance.get()))
            localStorage.setItem('lucroRestante11',gLucroRestante.get())
            
            gProxValor.set(() => losses[0]/100 * gBalance.get())
            localStorage.setItem('proxValor11',parseFloat(gProxValor.get()))

        }
        if (gProxValor.get()){
            updateDataSlice(userIdMemoized, 
                {   ganhei: gGanhei.get(),
                    acertos11: gAcertos.get(),
                    lucroRestante11: gLucroRestante.get(),
                    balance: gBalance.get(),
                    proxValor11: gProxValor.get(),
                    ciclos11: gCiclos.get(),
                    oportunidades11: gOportunidades.get()
                })
        }else{
            updateDataSlice(userIdMemoized, 
                {   ganhei: gGanhei.get(),
                    acertos11: gAcertos.get(),
                    lucroRestante11: gLucroRestante.get(),
                    balance: gBalance.get(),
                    ciclos11: gCiclos.get(),
                    oportunidades11: gOportunidades.get()
                })
        }
        
        
    }

    const handlePerdi = () => {
        let dt = getDateTime()

        gHistory.set(p => [
            {   
                date: dt.date,
                time: dt.time,
                invested: gProxValor.get(),
                result: gProxValor.get()*(-1)
            }, ...p
        ])

        // db.collection('customers').doc(userIdMemoized).collection('trades').add({   
        //     date: dt.date,
        //     time: dt.time,
        //     invested: gProxValor.get(),
        //     result: gProxValor.get()*(-1),
        //     timestamp: firebase.firestore.FieldValue.serverTimestamp()
        // })

        // db.collection('customers').doc(userIdMemoized)
        // .collection('trades')
        // .orderBy('timestamp','desc')
        // .onSnapshot( tradeSnapshot => {
        //     gHistory.set(tradeSnapshot.docs.map(
        //        trade => trade?.data()
        //     ))
        // })

        gBalance.set((p => (p -  gProxValor.get()) > 0 ? p -  gProxValor.get() : 0))
        
        localStorage.setItem('balance',gBalance.get())
        
        gPerdi.set( p => p + 1 )
        localStorage.setItem('perdi', gPerdi.get())
        
        gOportunidades.set((p => p - 1))
        localStorage.setItem('oportunidades11',gOportunidades.get())
        
        
        gLucroRestante.set(p => p + gProxValor.get())
        localStorage.setItem('lucroRestante11',gLucroRestante.get())
        
        
        let valor = getValorPerdi()
        
        gProxValor.set(() => valor)
        localStorage.setItem('proxValor11',parseFloat(gProxValor.get()))
        
        gAcertos.set(0)
        localStorage.setItem('acertos11',0)
        
       
        
        if (gOportunidades.get() <= 0) {
            gOportunidades.set(oportunidades)
            localStorage.setItem('oportunidades11',gOportunidades.get())
            
            
            gCiclos.set(p => p + 1)
            localStorage.setItem('ciclos11',gCiclos.get())
            
            gAcertos.set(0)
            localStorage.setItem('acertos11',0)
            
            
            gLucroRestante.set(() => (gRetornoPorCiclo.get() * gBalance.get()))
            localStorage.setItem('lucroRestante11',gLucroRestante.get())
            
            
            gProxValor.set(() => gLucroRestante.get())
            localStorage.setItem('proxValor11',parseFloat(gProxValor.get()))

        }
        if (gProxValor.get()){

            updateDataSlice(userIdMemoized, 
                {   
                    balance: gBalance.get(),
                    perdi: gPerdi.get(),
                    oportunidades11: gOportunidades.get(),
                    lucroRestante11: gLucroRestante.get(),
                    proxValor11: gProxValor.get(),
                    acertos11: 0,
                    ciclos11: gCiclos.get()
                })
        }else{
            updateDataSlice(userIdMemoized, 
                {   
                    balance: gBalance.get(),
                    perdi: gPerdi.get(),
                    oportunidades11: gOportunidades.get(),
                    lucroRestante11: gLucroRestante.get(),
                    acertos11: 0,
                    ciclos11: gCiclos.get()
                })
        }
    }

    useEffect(() => {
        readPlan(userIdMemoized)
    },[])

    useEffect(() => {
        gWins.set(() => wins)
        gLosses.set(() => losses)
        const savedOp = parseInt(localStorage.getItem("oportunidades11"))
        gOportunidades.set(() => savedOp ? savedOp : oportunidades)
        gMinimum.set(() => 1602)
        const proxValor = localStorage.getItem("proxValor11")
        gProxValor.set(() => parseFloat(proxValor) ? parseFloat(proxValor) : gInitialBalance.get() * losses[0]/100)
        updateDataSlice(userIdMemoized, { proxValor11: gProxValor.get() })
    },[gBalance.get()])

    useEffect(() => {
        updateDataSlice(userIdMemoized, { proxValor11: gProxValor.get() })
    },[gProxValor.get()])

    return (
        <>
            {desktopDisplay ?
            <MainDesktop
             gProxValor = {gProxValor.get()}
             gCiclos = {gCiclos.get()}
             gOportunidades = {gOportunidades.get()}
             oportunidades = {oportunidades}
             gAcertos = {gAcertos.get()}
             gRetornoPorCiclo = {gRetornoPorCiclo.get()}
             gPayout = {gPayout.get()}
             acertosPorCiclo = {acertosPorCiclo}
             desktopDisplay
             />
            
            :
            <div className="mainCard">
            <MainDesktop
             gProxValor = {gProxValor.get()}
             gCiclos = {gCiclos.get()}
             gOportunidades = {gOportunidades.get()}
             oportunidades = {oportunidades}
             gAcertos = {gAcertos.get()}
             gRetornoPorCiclo = {gRetornoPorCiclo.get()}
             gPayout = {gPayout.get()}
             acertosPorCiclo = {acertosPorCiclo}
             />
            
            {
                
                connected ?
                <div className="mainCard__topRight">
                    {/* BEGIN: Alert */}
                    {open && <Alert onClose={handleClose} severity="error" style={{position: 'absolute', zIndex: 101, width: 300, right: 30}}>{error}</Alert>}
                    {/* END: Alert */}
                    <Button onClick={handleToggle} className={gPlay.get() ? 'mainCard__perdi' : 'mainCard__ganhei'}>
                        {gPlay.get() ? <StopIcon /> : <PlayArrowIcon />}
                        <span>{gPlay.get() ? 'SAIR' : 'INICIAR'}</span>
                    </Button>
                    <Button onClick={handleOpenConfig} className="mainCard__perdi mainCard__config">
                        <SettingsIcon />
                        <span>CONFIG.</span>
                    </Button>
                    <Modal
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        open={openConfig}
                        onClose={handleCloseConfig}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <TelaConfigRobo handleCloseConfig={handleCloseConfig} />
                    </Modal>
                </div>
                :
                <div className="mainCard__topRight">
                    <Button onClick={handleGanhei} className="mainCard__ganhei">
                        <ThumbUpIcon />
                        <span>GANHEI</span>
                    </Button>
                    <Button onClick={handlePerdi} className="mainCard__perdi">
                        <ThumbDownAltIcon />
                        <span>PERDI</span>
                    </Button>
                    {
                    gSubscription.get() === "Automatizado" &&
                    <a href='robo-18-jan-22.exe' download>
                        <Info description="Faça o download do Robô">
                            <div className="mainCard__topRightButtonDownload">
                                <FileDownload style={{fontSize:22}}/>
                                <span>Download</span>
                            </div>
                        </Info>
                    </a>
                    }
                </div>
            }
            </div>
            }
        </> 
)
}

export default MainCard
