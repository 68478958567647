import './TelaLogoutRobo.css'
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'
import { globalConnected } from './Global'
import { useState as useGlobalState } from '@hookstate/core'


function TelaLogoutRobo({ handleCloseLogin, ...props }) {

    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const roboEmail = localStorage.getItem('roboEmail')
    const userId = JSON.parse(localStorage.getItem('user'))
    const gConnected = useGlobalState(globalConnected)

    const handleLogout = (e) => {
        e.preventDefault()

        setLoading(true)

        //
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password, logout: true })
        };
        fetch(`http://localhost:5000/users/${userId.user.uid}`, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
    
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setLoading(false)
                gConnected.set(() => false)
                localStorage.setItem('connected', false)
            })
            .catch(error => {
                setLoading(false)
            });
        //

        gConnected.set(() => false)
        localStorage.setItem('connected', false)
        setLoading(false)
        setSent(true)
        handleCloseLogin()
      }
      
      

    return (
        <div className="telaLogoutRobo">
          <IconButton {...props} onClick={handleCloseLogin} style={{ color: 'white', position: 'absolute', top: '5px', right: '5px', }}>
            <CloseIcon />
          </IconButton>

          <div className="telaLogoutRobo__header">
                <div className="telaLogoutRobo__headerTop">
                    <h1>Conectado a IQ Option</h1>
                </div>
                <span>{roboEmail}</span>
            </div>
            {/* <div className="telaLogoutRobo__header" onClick={handleCloseLogin}>
                    <h1>Conecte-se a IQ Option</h1>
                        <CloseIcon/>
            </div> */}

            <form color="warning" onSubmit={handleLogout}>

                <div className="telaLogoutRobo__button">

                                        
                    <Button  variant="contained" style={{ flex: 1 }} color="primary" type="submit" onClick={handleLogout}>
                    {sent ? 'Carregado!' : 
                    loading ? 'Carregando...'  : 
                    'DESCONECTAR'}
                    
                    {sent && <CheckCircleIcon/>}
                    {loading && <CircularProgress size={20} />}
                    </Button>

                </div>

            </form>

        </div>
    )
}

export default TelaLogoutRobo