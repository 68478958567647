import { createSlice } from '@reduxjs/toolkit';

export const softwareSlice = createSlice({
  name: 'software',
  initialState: {
      data: {
              plan: 0,
              option: 0,
              ganhei: 0,
              perdi: 0,
              acertos00: 0,
              ciclos00: 0,
              lucroRestante00: 0,
              oportunidades00: 15,
              proxValor00: 0,
              acertos01: 0,
              ciclos01: 0,
              lucroRestante01: 0,
              oportunidades01: 14,
              proxValor01: 0,
              acertos02: 0,
              ciclos02: 0,
              lucroRestante02: 0,
              oportunidades02: 10,
              proxValor02: 0,
              acertos03: 0,
              ciclos03: 0,
              lucroRestante03: 0,
              oportunidades03: 7,
              proxValor03: 0,
              acertos10: 0,
              ciclos10: 0,
              lucroRestante10: 0,
              oportunidades10: 12,
              proxValor10: 0,
              acertos11: 0,
              ciclos11: 0,
              lucroRestante11: 0,
              oportunidades11: 30,
              proxValor11: 0,
              acertos12: 0,
              ciclos12: 0,
              lucroRestante12: 0,
              oportunidades12: 20,
              proxValor12: 0
      }
  },
  reducers: {
    software: ( state, action ) => {
      state.data = action.payload;
    }
  }
});

export const { software } = softwareSlice.actions;

export const selectSoftware = (state) => state.software.data;

export default softwareSlice.reducer;


