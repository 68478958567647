import './SoftwarePhotos.css'

function SoftwarePhotos() {
    return (
        <div className="softwarePhotos">
            <div className="softwarePhotos__inner">
                <h1>NOSSO SOFTWARE</h1>
                <img src="software_photos.png" />
                
            </div>
        </div>
    )
}

export default SoftwarePhotos
