import { useEffect, useState } from 'react';
import './App.css';
import Header from './Header';
import HomeScreen from './HomeScreen'
import Login from './Login'
import { useState as useGlobalState } from '@hookstate/core'
import globalBalance, { globalBuying, globalSubscription, globalUser } from './Global'
import Faq from './Faq'
import Testimonials from "./Testimonials.js";
import SoftwarePhotos from './SoftwarePhotos';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import MeuPlano from './MeuPlano'
// import db from './firebase'
import NewPlans from './NewPlans';
import Footer from './Footer';
import VideoSection from './VideoSection';
import { useSelector } from 'react-redux'
import { selectUser } from './features/userSlice';
import { readBalance } from './firebaseCRUD';
import TelaConfigRobo from './TelaConfigRobo';
import Robo from './Robo';
import Termos from './Termos'

function App() {
  const gUser = useGlobalState(globalUser)
  const gSubscription = useGlobalState(globalSubscription)
  const user = useSelector(selectUser)

  useEffect(() => {
    localStorage.setItem('uid',user?.uid)
  },[])


  // const [subscription, setSubscription] = useState({})
  
  const getUserId = () => {
    return typeof gUser.get() === 'string' ? JSON.parse(gUser.get())?.user?.uid : 
    typeof gUser.get() === 'object' ? gUser.get()?.user?.uid : ""
  }

  // useEffect(() => {
  //     db.collection('customers')
  //     .doc(getUserId())
  //     .collection('subscriptions')
  //     .get()
  //     .then(querySnapshot => {
  //         querySnapshot.forEach(async subscription => {
  //             localStorage.setItem('subscription',subscription.data()?.role) 
  //             gSubscription.set(() => subscription.data()?.role)
  //             setSubscription({...subscription, role: subscription.data().role})
  //         })
  //     })
  // },[])

  return (
    <Router>
        <div className="app">
        <Switch>
        <Route path="/termos-de-servico">
          <Termos />
        </Route>
        <Route path="/robo">
          <Robo />
        </Route>

        <Route path="/meu-plano">
          {gUser.get() ?
              <MeuPlano />
            :
            <Redirect to="/" />  
          }
          </Route>
          
          <Route path="/dashboard">
          {gUser.get() && gSubscription.get() ?
          <HomeScreen />
          :
          <Redirect to="/meu-plano" />  
          
          }
          {/* {gUser.get() && gSubscription.get()?
              <HomeScreen />
            // :
            // gUser.get() && !gSubscription.get()?
            //   <Redirect to="/meu-plano" />
            // :
            // <Redirect to="/" />  
          } */}
          </Route>
          

          <Route path="/">
          {gUser.get() ? // && gSubscription.get()?
              <Redirect to="/dashboard" />
            // :
            // gUser.get() && !gSubscription.get()?
            //   <Redirect to="/meu-plano" />
            :
            <>
              <Header />
              <Login />
              <VideoSection />
              <SoftwarePhotos />
              <Testimonials />
              <NewPlans />
              <Footer />
            </>
          }
          </Route>

          <Route path="/">
            <>
              <Header />
              <Login />
              <VideoSection />
              <SoftwarePhotos />
              <Testimonials />
              {/* <Faq /> */}
              <NewPlans />
              <Footer />
            </>
          </Route>
        </Switch>
      </div>
      </Router>
  );
}

export default App;
