import './VideoSection.css'
import CarouselVideo from './CarouselVideo'
import { videos } from './videoData'

function VideoSection() {
    return (
        <div className="videoSection">
            <h1>COMO UTILIZAR</h1>
            <CarouselVideo className="videoSection__carousel" data={videos} />
        </div>
    )
}

export default VideoSection
